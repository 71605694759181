import React, { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PropTypes from 'prop-types'

import stripeSvg from '../../assets/images/stripe.svg'

import AccountLayout from '../../layout/account-layout'

import PaymentCard from './payment-card'
import PaymentForm from './payment-form'

import styles from './payment-page.module.scss'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY)

const elementsOptions = {
    fonts: [
        {
            cssSrc:
                'https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap'
        }
    ]
}

const PaymentPageView = ({ error, savePayment, city, countryData }) => {
    const membership = localStorage.getItem('membership')
    const titleText =
        membership === 'MONTH' ? 'Monthly plan' : 'Annual plan - ALL the benefits '

    const [couponData, setCouponData] = useState()

    return (
        <AccountLayout>
            <PaymentCard title={titleText} membership={membership} coupon={couponData} />

            <Elements stripe={stripePromise} options={elementsOptions}>
                <PaymentForm
                    error={error}
                    savePayment={savePayment}
                    countryData={countryData}
                    city={city}
                    setCouponData={setCouponData}
                />
            </Elements>
            <p className={styles['payment-policy']}>
                Your subscription will renew automatically each year/month
            </p>
            <p className={styles['payment-policy']}>
                All fields are required. By clicking the button you agree to the{' '}
                <a target="blank" href="https://bannister.coach/terms/">
                    Terms of Use
                </a>
                ,{' '}
                <a target="blank" href="https://bannister.coach/privacy-policy/">
                    Privacy Policy and Refund Policy
                </a>
                .
            </p>
            <img src={stripeSvg} alt="stripe" className={styles['stripe-svg']} />
        </AccountLayout>
    )
}

PaymentPageView.propTypes = {
    error: PropTypes.string,
    savePayment: PropTypes.func,
    countryData: PropTypes.objectOf(PropTypes.any),
    city: PropTypes.string
}

export default PaymentPageView
