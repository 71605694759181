import React from 'react'

import styles from './loader.module.scss'

const Loader = () => {
    return (
        <div className={styles.center}>
            <div className={styles['lds-default']}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    )
}

export default Loader
