import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Link } from 'react-router-dom'

import Logo from './logo'

import { authStore } from '../store'

import FooterLinks from '../layout/footer-links'

import styles from './common.module.scss'

const MiddleContainer = ({ children, logoSrc, margin, progress, center }) => {
    const { dispatch, state } = useContext(authStore)

    function logout() {
        dispatch({ type: 'LOGOUT' })
    }

    function renderHeader() {
        if (progress === undefined || progress === false) {
            if (logoSrc !== 'none') {
                return <Logo logoSrc={logoSrc} />
            }
            return false
        }

        return logoSrc === 'none' ? (
            <ProgressBar variant="primary" now={progress} className={styles.progress} />
        ) : (
            <Logo logoSrc={logoSrc} />
        )
    }

    return (
        <div
            className={`${styles['middle-container']} ${
                margin === true && styles.margin
            } ${center === true && styles.center}`}
        >
            {' '}
            <div>
                <div
                    className={`${styles['header-container']} ${
                        (!state.isAuthenticated || logoSrc === 'none') && styles.center
                    }`}
                >
                    {renderHeader()}
                    {logoSrc !== 'none' && state.isAuthenticated && (
                        <Link to="/login" onClick={logout}>
                            Sign out
                        </Link>
                    )}
                </div>
                {children}
            </div>
            {logoSrc !== 'none' && <FooterLinks />}
        </div>
    )
}

MiddleContainer.propTypes = {
    children: PropTypes.node.isRequired,
    logoSrc: PropTypes.string,
    margin: PropTypes.bool,
    center: PropTypes.bool,
    progress: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

export default MiddleContainer
