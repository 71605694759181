import React from 'react'
import PropTypes from 'prop-types'

import styles from './payment-page.module.scss'

const PaymentCard = ({ title, membership, coupon }) => {
    function calculateCouponValue() {
        const total = 336
        if (coupon) {
            if (coupon.type === 'percentage') {
                return (coupon.value / 100) * total
            }
            return coupon.value
        }
        return 0
    }
    return (
        <div className={styles['payment-card-wrapper']}>
            <p className={styles['payment-card-title']}>{title}</p>
            {membership === 'MONTH' ? (
                <div className={styles['payment-card-body']}>
                    <div className={styles.line}>
                        <p>Monthly Access</p>
                        <p>$40</p>
                    </div>
                    <div className={styles.line}>
                        <p>Setup Fee</p>
                        <div>
                            <p className={`strike ${styles.grey}`}>$100</p>
                            <p>$0</p>
                        </div>
                    </div>
                    <div className={styles.total}>
                        <p>Total Today</p>
                        <p>$40</p>
                    </div>
                </div>
            ) : (
                <div className={styles['payment-card-body']}>
                    <div className={styles.line}>
                        <p>
                            12 month access <span className={styles.grey}>(30% off)</span>
                        </p>
                        <div>
                            <p className={`strike ${styles.grey}`}>$480</p>
                            <p>$336</p>
                        </div>
                    </div>
                    
                    <div className={styles.line}>
                        <p>Setup Fee</p>
                        <div>
                            <p className={`strike ${styles.grey}`}>$100</p>
                            <p>$0</p>
                        </div>
                    </div>
                    {coupon && (
                        <div className={styles.line}>
                            <p>Coupon Code</p>
                            <p>$-{calculateCouponValue()}</p>
                        </div>
                    )}
                    <div className={styles.total}>
                        <p>Total Today</p>
                        <p>${336 - calculateCouponValue()}</p>
                    </div>
                </div>
            )}
        </div>
    )
}

PaymentCard.propTypes = {
    title: PropTypes.string,
    membership: PropTypes.string,
    coupon: PropTypes.objectOf(PropTypes.any)
}

export default PaymentCard
