import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import { isTokenExpired, userData } from '../constants/utils'

const initialState = {
    isAuthenticated: isTokenExpired(),
    user: userData.get(),
    token: null
}

const authStore = createContext(initialState)
const { Provider } = authStore

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            userData.set(action.data)

            return {
                ...state,
                isAuthenticated: true,
                user: action.data,
                token: null
            }

        case 'UPDATE_STEP':
            const user = {
                ...state.user,
                step: action.data.step
            }

            userData.set(user)
            return {
                ...state,
                user
            }

        case 'LOGOUT':
            console.log('logout')
            userData.delete()
            return {
                ...state,
                isAuthenticated: false,
                user: null
            }
        default:
            return state
    }
}

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

StateProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export { authStore, StateProvider }
