import React from 'react'
import PropTypes from 'prop-types'

import NavigationBar from '../../layout/navigation-bar'
import Footer from '../../layout/footer'

import AdministrationUsersTable from './administration-users-table'

import styles from './administration-page.module.scss'

const AdministrationPageView = ({ users }) => {
    return (
        <div className={styles.administration}>
            <NavigationBar />
            <div className={styles['administration-table-wrapper']}>
                <p className={styles.header}>Users list</p>
                <AdministrationUsersTable users={users} />
            </div>
            <Footer />
        </div>
    )
}

AdministrationPageView.propTypes = {
    users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
}

export default AdministrationPageView
