import React, { useContext } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'

import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { authStore } from '../store'

import bannisterLogoWhite from '../assets/images/logo-white.png'

import styles from '../components/common.module.scss'

const NavigationBar = () => {
    const { dispatch, state } = useContext(authStore)

    function logout() {
        dispatch({ type: 'LOGOUT' })
    }

    return (
        <Navbar expand="lg" className={styles.navigation}>
            <Navbar.Brand href="/training-plan">
                <img src={bannisterLogoWhite} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" icon={faUserCircle} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={styles['navigation-links']}>
                    <div className={styles.wrapper}>
                        <Nav.Link as={Link} to="/training-plan">
                            Training
                        </Nav.Link>
                        {state.user.role === 'admin' && (
                            <Nav.Link as={Link} to="/administration">
                                Administration
                            </Nav.Link>
                        )}
                    </div>
                    <div className={styles.wrapper}>
                        <div className={styles.user}>
                            <FontAwesomeIcon icon={faUserCircle} color="white" />
                            <Nav.Link as={Link} to="/profile">
                                {`${state.user.firstName} ${state.user.lastName}`}
                            </Nav.Link>
                        </div>

                        <Nav.Link as={Link} to="/login" onClick={logout}>
                            Sign out
                        </Nav.Link>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavigationBar
