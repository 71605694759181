import React, { useState, useContext } from 'react'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import { useHistory } from 'react-router-dom'

import { authStore } from '../../store'

import DatePicker from '../../components/datepicker'
import TrainingProfileLayout from '../../layout/training-profile-layout'

const TrainingProfileRaceDatePage = () => {
    const history = useHistory()
    const { dispatch } = useContext(authStore)

    const [raceDate, setRaceDate] = useState()

    function saveTime() {
        const trainingProfile = JSON.parse(localStorage.getItem('training-profile'))
        trainingProfile.raceDate = format(raceDate, 'yyyy-MM-dd')
        localStorage.setItem('training-profile', JSON.stringify(trainingProfile))
        dispatch({ data: { step: 'RACE_DATE_SELECTED' }, type: 'UPDATE_STEP' })
        history.push('/training-profile-speed-unit')
    }

    return (
        <TrainingProfileLayout
            title="Date of your race?"
            subtitle="Select below"
            progress={50}
            action={saveTime}
            disabled={!raceDate}
        >
            <div style={{ marginTop: '20px' }}>
                <DatePicker
                    time={raceDate}
                    filter
                    onTimeChange={(e) => setRaceDate(e)}
                    minDate={addDays(new Date(), 7)}
                />
            </div>
        </TrainingProfileLayout>
    )
}

export default TrainingProfileRaceDatePage
