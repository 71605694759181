import React, { useContext } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { stepToRouteMapper } from '../constants/utils'
import { authStore } from '../store'

const PrivateRoute = ({ children, auth, ...props }) => {
    const { state } = useContext(authStore)
    const location = useLocation()

    if (state.isAuthenticated) {
        const route = stepToRouteMapper[state.user.step]

        if (auth || (location.pathname.split('/')[1] === route)) {
            return <Route {...props}>{children}</Route>
        }
    }

    return <Redirect to="/login" />
}

PrivateRoute.propTypes = {
    children: PropTypes.node.isRequired,
    auth: PropTypes.bool
}

export default PrivateRoute
