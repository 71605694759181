import React, { useContext } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'

import bannisterLogoWhite from '../assets/images/logo-white.png'

import { authStore } from '../store'

import styles from '../components/common.module.scss'

const Header = () => {
    const { dispatch, state } = useContext(authStore)

    function logout() {
        dispatch({ type: 'LOGOUT' })
    }
    return (
        <Navbar expand="lg" className={styles.header}>
            <Navbar.Brand href="#home">
                <img src={bannisterLogoWhite} alt="logo" />
            </Navbar.Brand>
            {state.isAuthenticated && (
                <Nav.Link
                    as={Link}
                    to="/login"
                    onClick={logout}
                    className={styles.logout}
                >
                    Sign out
                </Nav.Link>
            )}
        </Navbar>
    )
}

export default Header
