import React, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import { faClock } from '@fortawesome/free-solid-svg-icons'
import { UPDATE_SIX_MIN_TRAIL } from '../../../graphql/mutations'
import { GET_USER_TRAINING_PROFILE } from '../../../graphql/queries'

import { authStore } from '../../../store'

import Input from '../../../components/input'

import styles from './profile.module.scss'

const ProfileSixMinTime = ({ sixMinTimeTrails }) => {
    const [responseMessage, setResponseMessage] = useState()
    const [error, setError] = useState(false)
    const [display, setDisplay] = useState(false)
    const { state } = useContext(authStore)
    const initialValues = {
        sixMinTimeTrailMinutes: sixMinTimeTrails[sixMinTimeTrails.length - 1].minutes,
        sixMinTimeTrailSeconds: sixMinTimeTrails[sixMinTimeTrails.length - 1].seconds
    }

    const [updateSixMinTimeTrailMutation] = useMutation(UPDATE_SIX_MIN_TRAIL, {
        errorPolicy: 'all'
    })

    function updateSixMinTimeTrail(values, resetForm) {
        updateSixMinTimeTrailMutation({
            variables: {
                updateSixMinTimeTrailMin: values.sixMinTimeTrailMinutes,
                updateSixMinTimeTrailSec: values.sixMinTimeTrailSeconds,
                updateSixMinTimeTrailUid: state.user.id
            },
            refetchQueries: [
                { query: GET_USER_TRAINING_PROFILE, variables: { uid: state.user.id } }
            ]
        }).then((res) => {
            resetForm()
            setDisplay(true)
            setInterval(() => {
                setDisplay(false)
            }, 3000)

            if (res.errors) {
                setError(true)
                setResponseMessage(res.errors[0].message)
            } else {
                setResponseMessage('6-minutes test time was updated successfully!')
            }
        })
    }

    return (
        <div>
            <Formik
                enableReinitialize
                validateOnBlur={false}
                initialValues={initialValues}
                validate={(values) => {
                    const errors = {}

                    if (
                        values.sixMinTimeTrailMinutes === 0 &&
                        values.sixMinTimeTrailSeconds === 0
                    ) {
                        errors.sixMinTimeTrailSeconds = 'Required'
                        errors.sixMinTimeTrailMinutes = 'Required'
                    }

                    if (
                        !values.sixMinTimeTrailMinutes &&
                        values.sixMinTimeTrailMinutes !== 0
                    ) {
                        errors.sixMinTimeTrailMinutes = 'Required'
                    }

                    if (
                        !values.sixMinTimeTrailSeconds &&
                        values.sixMinTimeTrailSeconds !== 0
                    ) {
                        errors.sixMinTimeTrailSeconds = 'Required'
                    }

                    return errors
                }}
                onSubmit={(values, { resetForm }) =>
                    // eslint-disable-next-line
                    updateSixMinTimeTrail(values, resetForm)}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    dirty,
                    isValid,
                    isSubmitting
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <div>
                            <div className={styles.card}>
                                <p className={styles.label}>
                                    6-Minute Test Pace in min/km
                                </p>
                                <div className={styles['right-wrapper']}>
                                    <Input
                                        icon={faClock}
                                        placeholder="minutes"
                                        name="sixMinTimeTrailMinutes"
                                        type="number"
                                        value={values.sixMinTimeTrailMinutes}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched.sixMinTimeTrailMinutes}
                                        error={errors.sixMinTimeTrailMinutes}
                                        border
                                    />
                                    <Input
                                        icon={faClock}
                                        placeholder="seconds"
                                        name="sixMinTimeTrailSeconds"
                                        type="number"
                                        value={values.sixMinTimeTrailSeconds}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched.sixMinTimeTrailSeconds}
                                        error={errors.sixMinTimeTrailSeconds}
                                        border
                                    />
                                    <Button
                                        variant="outline-primary"
                                        className="inline-button"
                                        type="submit"
                                        disabled={isSubmitting || !(dirty && isValid)}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                            <a
                                target="blank"
                                className={styles.link}
                                href="https://bannister.coach/minutes-per-mile-to-minutes-per-km-calculator/"
                            >
                                Convert from minutes/mile to minutes/km here
                            </a>
                        </div>
                        {responseMessage && (
                            <p
                                className={styles.response}
                                style={{
                                    color: error ? '#f45628' : '#00D88D',
                                    display: display === true ? 'block' : 'none'
                                }}
                            >
                                {responseMessage}
                            </p>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

ProfileSixMinTime.propTypes = {
    sixMinTimeTrails: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
}

export default ProfileSixMinTime
