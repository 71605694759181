import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import Loader from '../../components/loader'
import AccountLayout from '../../layout/account-layout'

import LoginPageView from './login-page-view'

import { LOGIN } from '../../graphql/mutations'
import { authStore } from '../../store'

import { stepToRouteMapper } from '../../constants/utils'

export default function LoginPageContainer() {
    const history = useHistory()

    const { dispatch } = useContext(authStore)
    const [isSubmitting, setSubmitting] = useState(false)

    const [loginMutation] = useMutation(LOGIN, {
        errorPolicy: 'all'
    })
    const [error, setError] = useState()

    function login(values) {
        setSubmitting(true)

        loginMutation({
            variables: { credentials: values }
        }).then((res) => {
            if (res.errors) {
                setError(res.errors[0].message)
                setSubmitting(false)
            } else {
                dispatch({ data: res.data.login, type: 'LOGIN' })

                if (res.data.login.step) {
                    const step = res.data.login.step
                    history.push(`/${stepToRouteMapper[step]}`)
                } else {
                    history.push('/login')
                }
            }
        })
    }

    if (isSubmitting) {
        return (
            <AccountLayout>
                <Loader />
            </AccountLayout>
        )
    }

    return <LoginPageView error={error} login={login} isSubmitting={isSubmitting} />
}
