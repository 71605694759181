import React from 'react'
import PropTypes from 'prop-types'

import styles from './common.module.scss'

const Subtitle = ({ text }) => {
    return <p className={styles.subtitle} dangerouslySetInnerHTML={{ __html: text }} />
}

Subtitle.propTypes = {
    text: PropTypes.string
}

export default Subtitle
