import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { authStore } from '../../store'

import { faTachometerAlt, faRuler } from '@fortawesome/free-solid-svg-icons'

import TrainingProfileLayout from '../../layout/training-profile-layout'
import GenericCard from '../../components/generic-card'

import styles from './training-profile-speed-unit.module.scss'

const TrainingProfileSpeedUnitPageView = () => {
    const history = useHistory()
    const { dispatch } = useContext(authStore)

    const [speedUnit, setSpeedUnit] = useState('')

    function saveSpeedUnit() {
        const trainingProfile = JSON.parse(localStorage.getItem('training-profile'))
        trainingProfile.speedUnit = speedUnit
        localStorage.setItem('training-profile', JSON.stringify(trainingProfile))
        dispatch({ data: { step: 'SPEED_UNIT_SELECTED' }, type: 'UPDATE_STEP' })
        history.push('/training-profile-program')
    }

    return (
        <TrainingProfileLayout
            title="What speed unit do you use?"
            subtitle="Select below"
            progress={75}
            disabled={!speedUnit}
            action={saveSpeedUnit}
        >
            <div className={styles.cards}>
                <GenericCard
                    icon={faTachometerAlt}
                    selected={speedUnit === 'km'}
                    gender="min/kilometer"
                    action={() => setSpeedUnit('km')}
                />
                <GenericCard
                    icon={faRuler}
                    selected={speedUnit === 'mile'}
                    gender="min/Mile"
                    action={() => setSpeedUnit('mile')}
                />
            </div>
        </TrainingProfileLayout>
    )
}

export default TrainingProfileSpeedUnitPageView
