import React, { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import PaymentPageView from './payment-page-view'
import AccountLayout from '../../layout/account-layout'
import Loader from '../../components/loader'

import { PAYMENT } from '../../graphql/mutations'

import { authStore } from '../../store'

export default function PaymentPageContainer() {
    const history = useHistory()
    const { dispatch } = useContext(authStore)

    const [countryName, setCountryName] = useState()
    const [countryCode, setCountryCode] = useState()
    const [city, setCity] = useState()

    const [sendPaymentMutation] = useMutation(PAYMENT, {
        errorPolicy: 'all'
    })
    const [error, setError] = useState()
    const [isSubmitting, setSubmitting] = useState(false)

    function savePayment(values, stripe) {
        setSubmitting(true)

        sendPaymentMutation({ variables: { paymentInfo: values } }).then((response) => {
            if (response.errors) {
                if (response.errors[0].extensions.validationErrors) {
                    setError(response.errors[0].extensions.validationErrors[0])
                } else {
                    setError(response.errors[0].message)
                }
                setSubmitting(false)
            } else if (response.data && response.data.sendPayment.requires_action) {
                stripe
                    .handleCardPayment(
                        response.data.sendPayment.payment_intent_client_secret
                    )
                    .then((result) => {
                        if (result.error) {
                            setError(result.error.message)
                            setSubmitting(false)
                        } else if (result.paymentIntent.status === 'succeeded') {
                            setError()
                            setSubmitting(false)
                            dispatch({ data: { step: 'SUBSCRIPTION_PAID' }, type: 'UPDATE_STEP' })
                            history.push('/create-profile')
                        }
                    })
                    .catch(() => {
                        setSubmitting(false)
                    })
            } else {
                setError()
                setSubmitting(false)
                dispatch({ data: { step: 'SUBSCRIPTION_PAID' }, type: 'UPDATE_STEP' })
                history.push('/create-profile')
            }
        })
    }

    function getGeoInfo() {
        setSubmitting(true)
        fetch('https://ipapi.co/json/')
            .then((response) => response.json())
            .then((response) => {
                setCountryName(response.country_name)
                setCountryCode(response.country)
                setCity(response.city)
                setSubmitting(false)
            })
    }

    useEffect(() => {
        getGeoInfo()
    }, [])

    if (isSubmitting) {
        return (
            <AccountLayout>
                <Loader />
            </AccountLayout>
        )
    }

    return (
        <PaymentPageView
            savePayment={savePayment}
            error={error}
            isSubmitting={isSubmitting}
            city={city}
            countryData={{ value: countryCode, label: countryName }}
        />
    )
}
