import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { CREATE_TRAINING_PLAN } from '../../graphql/mutations'

import { authStore } from '../../store'

import TrainingProfileLayout from '../../layout/training-profile-layout'
import ChangingProgressProvider from './changing-progress-provider'
import TrainingProfileProgramView from './training-profile-program-page-view'

import 'react-circular-progressbar/dist/styles.css'

export default function TrainingProfileProgramPageContainer() {
    const [selectedDays, setSelectedDays] = useState([])
    const [error, setError] = useState()
    const [isSubmitting, setSubmitting] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [consent, setConsent] = useState(false)

    const { state, dispatch } = useContext(authStore)

    const history = useHistory()

    const [upsertTrainingProfileMutation] = useMutation(CREATE_TRAINING_PLAN, {
        errorPolicy: 'all'
    })

    function isDaySelected(day) {
        return selectedDays.filter((selectedDay) => selectedDay.value === day.value)
    }

    function addSelectedDay(day) {
        if (selectedDays.length <= 5) {
            setSelectedDays([...selectedDays, day])
        }

        if (isDaySelected(day).length > 0) {
            const newSelectedDays = selectedDays.filter((selectedDay) => {
                return selectedDay.value !== day.value
            })

            setSelectedDays(newSelectedDays)
        }
    }

    function generateTrainingPlan() {
        setSubmitting(true)
        setShowLoader(true)
        const trainingProfile = JSON.parse(localStorage.getItem('training-profile'))
        trainingProfile.trainingDays = selectedDays.map(
            (selectedDay) => selectedDay.value
        )
        trainingProfile.uid = state.user.id
        localStorage.setItem('training-profile', JSON.stringify(trainingProfile))

        setTimeout(() => {
            upsertTrainingProfileMutation({
                variables: {
                    trainingProfile
                }
            }).then((res) => {
                if (res.errors) {
                    if (res.errors[0].extensions.validationErrors) {
                        setError(res.errors[0].extensions.validationErrors[0])
                    } else {
                        setError(res.errors[0].message)
                    }
                    setSubmitting(false)
                    setShowLoader(false)
                } else {
                    setError()
                    dispatch({ data: { step: 'COMPLETED' }, type: 'UPDATE_STEP' })
                    history.push('/training-plan')
                }
            })
        }, 2000)
    }

    if (showLoader) {
        return (
            <TrainingProfileLayout center>
                <div className="circle-wrapper">
                    <p className="circle-title">
                        We are creating your personal training plan.
                    </p>
                    <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
                        {(percentage) => (
                            <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                styles={buildStyles({
                                    textColor: '#212529',
                                    pathColor: '#fd1160',
                                    trailColor: 'rgba(253, 17, 96, 0.2)'
                                })}
                            />
                        )}
                    </ChangingProgressProvider>
                </div>
            </TrainingProfileLayout>
        )
    }

    return (
        <TrainingProfileProgramView
            selectedDays={selectedDays}
            addSelectedDay={addSelectedDay}
            isDaySelected={isDaySelected}
            generateTrainingPlan={generateTrainingPlan}
            error={error}
            isSubmitting={isSubmitting}
            consent={consent}
            setConsent={setConsent}
        />
    )
}
