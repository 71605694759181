import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import qs from 'qs'

import { faUser, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'

import AccountLayout from '../../layout/account-layout'
import Input from '../../components/input'

import styles from './create-account-page.module.scss'

const CreateAccountPageView = ({ createAccount, error }) => {
    const history = useHistory()
    const urlParams = qs.parse(window.location.search)

    const [passwordChecks, setPasswordChecks] = useState({
        eight: false,
        lowecase: false,
        uppercase: false,
        digit: false,
        special: false
    })

    const initialValues = {
        firstName: (urlParams?.thrivecart?.customer?.firstname) ? urlParams?.thrivecart?.customer?.firstname.trim() : '',
        lastName: (urlParams?.thrivecart?.customer?.lastname) ? urlParams?.thrivecart?.customer?.lastname.trim() : '',
        email: (urlParams?.thrivecart?.customer?.email) ? urlParams?.thrivecart?.customer?.email.trim() : '',
        password: '',
        confirmPassword: ''
    }

    function onPasswordChange(password) {
        const eight = password.length >= 8
        const lowercase = password.search(/[a-z]/) >= 0
        const uppercase = password.search(/[A-Z]/) >= 0
        const digit = password.search(/[0-9]/) >= 0
        const special = password.search(/[!@#$%^&*()--__+.]/) >= 0

        setPasswordChecks({
            ...passwordChecks,
            special,
            eight,
            lowercase,
            uppercase,
            digit
        })
    }

    if (!urlParams.thrivecart_hash) return window.location.href = 'https://checkout.bannister.coach/traning-plan-ck/'

    return (
        <AccountLayout title="Let’s set up your account" subtitle="Contact information">
            <Formik
                validateOnBlur={false}
                initialValues={initialValues}
                validate={(values) => {
                    const errors = {}

                    if (!values.firstName) {
                        errors.firstName = 'Required'
                    } else if (!/^[a-zA-Z]*$/g.test(values.firstName)) {
                        errors.firstName = 'Only letters are allowed'
                    }

                    if (!values.lastName) {
                        errors.lastName = 'Required'
                    } else if (!/^[a-zA-Z]*$/g.test(values.lastName)) {
                        errors.lastName = 'Only letters are allowed'
                    }

                    if (!values.email) {
                        errors.email = 'Required'
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,18}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address'
                    }

                    if (!values.password) {
                        errors.password = 'Required'
                    }

                    if (!values.confirmPassword) {
                        errors.confirmPassword = 'Required'
                    } else if (values.password !== values.confirmPassword) {
                        errors.confirmPassword = `Password and confirm password don't match`
                    }

                    return errors
                }}
                onSubmit={(values) =>
                    // eslint-disable-next-line
                    createAccount(values)}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    dirty,
                    isValid,
                    isSubmitting
                }) => (
                    <Form
                        className={styles['create-account-form']}
                        onSubmit={handleSubmit}
                    >
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Input
                                    icon={faUser}
                                    placeholder="First name"
                                    name="firstName"
                                    type="text"
                                    value={values.firstName}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.firstName}
                                    error={errors.firstName}
                                />
                                <Input
                                    icon={faUser}
                                    placeholder="Last name"
                                    name="lastName"
                                    type="text"
                                    value={values.lastName}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.lastName}
                                    error={errors.lastName}
                                />

                                <Input
                                    icon={faEnvelope}
                                    placeholder="Email"
                                    name="email"
                                    type="text"
                                    value={values.email}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.email}
                                    error={errors.email}
                                />

                                <Input
                                    icon={faLock}
                                    placeholder="Password"
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    handleChange={(e) => {
                                        onPasswordChange(e.target.value)
                                        handleChange(e)
                                    }}
                                    handleBlur={handleBlur}
                                    touched={touched.password}
                                    error={errors.password}
                                />

                                <div className="text-left">
                                    <div className="mb-2">Your password must have at least:</div>
                                    <ul>
                                        <li
                                            className={passwordChecks.eight ? styles['password-valid-check'] : ''}
                                        >8 characters
                                        </li>
                                        <li
                                            className={passwordChecks.lowercase ? styles['password-valid-check'] : ''}
                                        >One lowercase character</li>
                                        <li
                                            className={passwordChecks.digit ? styles['password-valid-check'] : ''}
                                        >One digit</li>
                                        <li
                                            className={passwordChecks.uppercase ? styles['password-valid-check'] : ''}
                                        >One uppercase character</li>
                                        <li
                                            className={passwordChecks.special ? styles['password-valid-check'] : ''}
                                        >One special character</li>
                                    </ul>
                                </div>

                                <Input
                                    icon={faLock}
                                    placeholder="Confirm password"
                                    name="confirmPassword"
                                    type="password"
                                    value={values.confirmPassword}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.confirmPassword}
                                    error={errors.confirmPassword}
                                    customClass="block"
                                />
                            </Form.Group>
                        </Form.Row>
                        {error && <div className="server-error-message">{error}</div>}
                        <Button
                            variant="primary"
                            className="custom-button width100"
                            type="submit"
                            disabled={
                                isSubmitting ||
                                !(dirty && isValid) ||
                                !passwordChecks.eight ||
                                !passwordChecks.lowercase ||
                                !passwordChecks.digit ||
                                !passwordChecks.uppercase ||
                                !passwordChecks.special
                            }
                        >
                            Continue
                        </Button>
                    </Form>
                )}
            </Formik>

            <p className={styles['privacy-policy']}>
                By registering, you agree to the Bannister{' '}
                <a target="blank" href="https://bannister.coach/terms/">
                    Terms
                </a>
                &nbsp; & &nbsp;
                <a target="blank" href="https://bannister.coach/cookies/">
                    Conditions
                </a>
                &nbsp; and &nbsp;
                <a target="blank" href="https://bannister.coach/privacy-policy/">
                    Privacy Policy
                </a>
                .
            </p>
            <p className={styles['sign-in']}>
                Already have an account? <Link to="/login">Sign In</Link>
            </p>
        </AccountLayout>
    )
}

CreateAccountPageView.propTypes = {
    error: PropTypes.string,
    createAccount: PropTypes.func
}

export default CreateAccountPageView
