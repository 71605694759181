import React from 'react'
import PropTypes from 'prop-types'

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { getYear, getMonth, getDay } from 'date-fns'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import styles from './common.module.scss'

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <InputGroup className={styles['input-border']} ref={ref}>
            <InputGroup.Prepend>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faCalendarAlt} onClick={props.onClick} />
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control {...props} />
        </InputGroup>
    )
})

CustomInput.propTypes = {
    onClick: PropTypes.func
}

const Datepicker = ({ time, onTimeChange, minDate, readOnly, startYear, filter }) => {
    const ref = React.createRef()

    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start)
    }

    const isWeekend = (date) => {
        const day = getDay(date)
        return day === 0 || day === 6
    }

    let years
    if (startYear) {
        years = range(startYear, getYear(new Date()))
    } else {
        years = range(2020, 2040)
    }

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    return (
        <DatePicker
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
            }) => (
                <div
                    style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <button
                        type="button"
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                    >
                        {'<'}
                    </button>
                    <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                            // eslint-disable-next-line
                            changeMonth(months.indexOf(value))}
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <button
                        type="button"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                    >
                        {'>'}
                    </button>
                </div>
            )}
            selected={time && new Date(time)}
            minDate={minDate}
            onChange={(date) => onTimeChange(date)}
            showMonthDropdown
            showYearDropdown
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            placeholderText="MM/DD/YYYY"
            className="form-control"
            readOnly={readOnly}
            dateFormat="dd/MM/yyyy"
            customInput={<CustomInput ref={ref} />}
            filterDate={filter && isWeekend}
        />
    )
}

Datepicker.propTypes = {
    time: PropTypes.instanceOf(Date),
    onTimeChange: PropTypes.func,
    minDate: PropTypes.objectOf(PropTypes.any),
    readOnly: PropTypes.bool,
    startYear: PropTypes.number,
    filter: PropTypes.bool
}

export default Datepicker
