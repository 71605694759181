import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Profile from './profile'
import Subscription from './subscription'

import styles from './user-profile-page.module.scss'

const ProfileNavigation = ({ userProfile }) => {
    return (
        <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="1"
            className={styles.tabs}
        >
            <Row>
                <Col sm={12} md={3} style={{ marginBottom: '20px' }}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link as={Link} className={styles.item} eventKey="1">
                                Profile
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={12} md={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="1">
                            <Profile userProfile={userProfile} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="4">
                            <Subscription />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}

ProfileNavigation.propTypes = {
    userProfile: PropTypes.objectOf(PropTypes.any)
}

export default ProfileNavigation
