export const userData = {
    get() {
        return (
            localStorage.getItem('user') !== undefined &&
            JSON.parse(localStorage.getItem('user'))
        )
    },
    set(data) {
        return data && localStorage.setItem('user', JSON.stringify(data))
    },
    delete() {
        return localStorage.removeItem('user')
    }
}

export const stepToRouteMapper = {
    ACCOUNT_CREATED: 'create-profile',
    SUBSCRIPTION_PAID: 'create-profile',
    YOUR_PROFILE_COMPLETED: 'create-profile',
    TRAINING_ACCESS_COMPLETED: 'create-profile',
    CARDIOVASCULAR_FITNESS_COMPLETED: 'create-profile',
    RUNNING_FITNESS_COMPLETED: 'create-profile',
    ONBOARDING_COMPLETED: 'training-profile-plan-length',
    PLAN_LENGTH_SELECTED: 'training-profile-race-date',
    RACE_DATE_SELECTED: 'training-profile-speed-unit',
    SPEED_UNIT_SELECTED: 'training-profile-program',
    COMPLETED: 'training-plan'
}

export const stepData = {
    get() {
        return localStorage.getItem('step')
    },
    set(step) {
        return localStorage.setItem('step', step)
    },
    delete() {
        return localStorage.removeItem('step')
    }
}

export const isTokenExpired = () => {
    const userInfo = userData.get()
    // TODO: Check for the expiration token also
    if (userInfo) {
        return true
    }
    return false
}

export const parseDateWithoutTimezone = (date) => {
    const dateInstance = new Date(date)
    const dateWitoutTimezone = new Date(
        dateInstance.valueOf() + dateInstance.getTimezoneOffset() * 60 * 1000
    )
    return dateWitoutTimezone
}

export const weekDays = [
    { label: 'M', value: 'Monday' },
    { label: 'T', value: 'Tuesday' },
    { label: 'W', value: 'Wednesday' },
    { label: 'T', value: 'Thursday' },
    { label: 'F', value: 'Friday' },
    { label: 'S', value: 'Saturday' },
    { label: 'S', value: 'Sunday' }
]

export const programLabels = {
    '5k': [
        'Easy Run',
        'Easy Run',
        'Interval',
        'Easy Run',
        'Strength Training',
        'Long Run'
    ],
    '21k': [
        'Strength Training',
        'Easy Run',
        'Easy Run',
        'Interval',
        'Easy Run',
        'Long Run'
    ],
    '10k': [
        'Easy Run',
        'Easy Run',
        'Interval',
        'Easy Run',
        'Strength Training',
        'Long Run'
    ],
    '42k': [
        'Strength Training',
        'Easy Run',
        'Easy Run',
        'Interval',
        'Easy Run',
        'Long Run'
    ],
    'build-up': [
        'Easy Run',
        'Strength Training',
        'Easy Run',
        'Interval',
        'Easy Run',
        'Long Run'
    ]
}

export function mapOrder(array, order, key) {
    array.sort((a, b) => {
        const A = a[key]
        const B = b[key]

        if (order.indexOf(A) > order.indexOf(B)) {
            return 1
        }
        return -1
    })

    return array
}

export const raceDayMessage =
    'Relax and Enjoy the race. Trust all the hard work you did. Remember to share your results with us and that we are here to help you with your next race!'

export const sixMinEstimateParam1 = { '5k': 5, '21k': 21.1, '10k': 10, '42k': 42.2 }
export const sixMinEstimateParam2 = {
    '5k': 1.083,
    '21k': 1.188,
    '10k': 1.124,
    '42k': 1.25
}

export function formatStringWithLink(string) {
    if (string.includes('http')) {
        return `<p>${
            string.split('https:')[0]
        }<a style="color: #FF1466;" target="_blank" href=https:${
            string.split('https:')[1]
        }>https:${string.split('https:')[1]}</a></p>`
    }
    return string
}
