import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { stepToRouteMapper } from '../constants/utils'
import { authStore } from '../store'

function RedirectIfAuthRoute({ component: Component, ...rest }) {
    const { state } = useContext(authStore)
    
    return <Route
        {...rest} render={props => (
            state.isAuthenticated ? (
                <Redirect to={{
                    pathname: `/${stepToRouteMapper[state.user.step]}`,
                    state: { from: props.location }
                }} />
            ) : (
                <Component {...props} />
            )
        )} />
}

export default RedirectIfAuthRoute
