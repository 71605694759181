import React from 'react'
import PropTypes from 'prop-types'

import styles from './training-plan-page.module.scss'

const TrainingZones = ({ hrrZones, paceZones }) => {
    return (
        <div className={styles['zones-wrapper']}>
            <div className={styles.left}>
                {Object.keys(hrrZones).map((key) => {
                    return (
                        <div key={key}>
                            <p>Zone {key}</p>
                            <p>
                                {hrrZones[key][0]} - {hrrZones[key][1]} BPM
                            </p>
                        </div>
                    )
                })}
            </div>
            <div className={styles.right}>
                {Object.keys(paceZones).map((key) => {
                    return (
                        key !== '0' && (
                            <div key={key}>
                                <p>Pace {key}</p>
                                <p>
                                    {paceZones[key][0]} - {paceZones[key][1]}
                                </p>
                            </div>
                        )
                    )
                })}
            </div>
        </div>
    )
}

TrainingZones.propTypes = {
    hrrZones: PropTypes.objectOf(PropTypes.any),
    paceZones: PropTypes.objectOf(PropTypes.any)
}

export default TrainingZones
