import React, { useState, useEffect } from 'react'
import ModalVideo from 'react-modal-video'

import Lottie from 'react-lottie'
import { faUser, faCheckCircle, faLink, faHeartbeat, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Container from 'react-bootstrap/Container'

import fireworksAnimationData from './fireworks-lottie.json'
import DatePicker from '../../components/datepicker'
import Header from '../../layout/header'
import Footer from '../../layout/footer'

//https://bannister.coach/bannister-onboarding-demo/

const textStyle = {
    fontSize: '18px',
    lineHeight: '1.3em',
}

const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: fireworksAnimationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

export default function CreateProfilePageView({
    connectStrava,
    onCreateFirstTrainingPlan,
    stravaError,
    upsertTrainingProfile,
    stepCompletionMap,
    initialValues
}) {
    const [isOpenRoutine, setOpenRoutine] = useState(false)
    const [isOpen6Min, setOpen6Min] = useState(false)

    return (
        <>
            <Header />
            <Container className="p-2 mt-4 d-flex justify-content-center" style={{ color: '#3a3a3a' }}>
                <div className="d-flex flex-column align-items-center" style={{ width: '680px' }}>
                    <h2
                        className="text-center p-3"
                        style={{ fontWeight: '600' }}
                    >
                        Welcome to Bannister!
                    </h2>

                    <div className="mt-2">
                        <Lottie
                            options={defaultLottieOptions}
                            height={150}
                            width={150}
                        />
                    </div>

                    <div className="mt-4 text-justify-sm">
                        <p style={textStyle}>
                            It’s fantastic to have you here. You are about to start training smarter and achieve all your running goals.
                        </p>

                        <p className="mt-4" style={textStyle}>
                            Just follow the next four steps and you will be ready to create your first 100% personalized and always optimized training plan.
                        </p>

                        <p className="mt-4" style={textStyle}>
                            Please, read carefully and follow the instructions. The more accurate your data, the more accurate and effective your training plan will be.
                        </p>

                        <p className="mt-4" style={textStyle}>
                            Let’s get started.
                        </p>
                    </div>

                    <div className="mt-5" style={{ width: '100%' }}>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon
                                    style={{ color: '#818a91' }}
                                    className="mr-2"
                                    icon={faUser} size="2x"
                                />
                                <h4 style={{ fontWeight: 'bold' }}>Step 1 - Your Profile</h4>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-4" >
                            <div>
                                Date Of Birth
                            </div>
                            <div>
                                <DatePicker
                                    time={initialValues.birthDay}
                                    startYear={1950}
                                    onTimeChange={(e) => upsertTrainingProfile('birthDay', e)}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-4" >
                            <div>
                                Sex
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-2"
                                    style={initialValues.sex === 'M' ? ({ backgroundColor: '#FF1466', width: '100px' }) : ({ backgroundColor: 'transparent', color: '#9F9F9F', border: '1px solid #9F9F9F', width: '100px' })}
                                    onClick={() => upsertTrainingProfile('sex', 'M')}
                                >
                                    Male
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={initialValues.sex === 'F' ? ({ backgroundColor: '#FF1466', width: '100px' }) : ({ backgroundColor: 'transparent', color: '#9F9F9F', border: '1px solid #9F9F9F', width: '100px' })}
                                    onClick={() => upsertTrainingProfile('sex', 'F')}
                                >
                                    Female
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <FontAwesomeIcon
                            style={stepCompletionMap.step1 ? { color: 'green' } : { color: '#818a91' }}
                            className="mr-2"
                            icon={faCheckCircle} size="2x"
                            inverse
                        />
                    </div>

                    <hr className="my-4" style={{ width: '100%', backgroundColor: '#3a3a3a' }} />

                    <div style={{ width: '100%' }}>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon
                                    style={{ color: '#818a91' }}
                                    className="mr-2"
                                    icon={faLink} size="2x"
                                />
                                <h4 style={{ fontWeight: 'bold' }}>Step 2 - Training Access</h4>
                            </div>
                        </div>


                        <div className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-between">
                            <div className="d-flex flex-column w-75 w-md-50 text-justify text-md-left" >
                                <p className="my-4">
                                    To make your training programs truly adaptive we need to gather data from your sports watch.
                                </p>
                                <p className="mb-4">
                                    The way to connect your sports watch to Bannister is through Strava.
                                </p>
                                <a
                                    target="blank"
                                    href="https://bannister.coach/knowledge-base/category/integrations/"
                                >Having trouble? Watch this tutorials</a>
                            </div>

                            <div className="d-flex flex-column justify-content-center align-items-center mt-4 mt-md-0" >
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{ backgroundColor: '#FF1466' }}
                                    onClick={() => connectStrava()}
                                    disabled={stepCompletionMap.step2}
                                >
                                    Connect Strava Here
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="text-center mt-4">
                        <div style={{ fontSize: '25px' }} className="error-message">{stravaError}</div>
                    </div>

                    <div className="mt-1">
                        <FontAwesomeIcon
                            style={stepCompletionMap.step2 ? { color: 'green' } : { color: '#818a91' }}
                            className="mr-2"
                            icon={faCheckCircle} size="2x"
                            inverse
                        />
                    </div>

                    <hr className="my-4" style={{ width: '100%', backgroundColor: '#3a3a3a' }} />

                    <div style={{ width: '100%' }}>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon
                                    style={{ color: '#818a91' }}
                                    className="mr-2"
                                    icon={faLink} size="2x"
                                />
                                <h4 style={{ fontWeight: 'bold' }}>Step 3 - Cardiovascular Fitness</h4>
                            </div>
                        </div>


                        <div className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-between">
                            <div className="d-flex flex-column w-75 w-md-50 text-justify text-md-left" >
                                <p className="my-4">
                                    Resting Heart rate is an essential metric to assess your fitness, and create your training plan.
                                </p>
                                <a target='blank' href='https://bannister.coach/knowledge-base/how-to-get-your-resting-hear-rate/'>
                                    Watch this video to learn how to get your resting heart rate.</a>
                            </div>

                            <div className="d-flex flex-column justify-content-center align-items-center mt-4 mt-md-0">
                                <div className="input-group mb-3" style={{ border: '1px solid #DCDCDC', width: '65%' }}>
                                    <span className="input-group-text" id="basic-addon1">
                                        <FontAwesomeIcon
                                            style={{ color: '#818a91' }}
                                            icon={faHeartbeat}
                                            inverse
                                        />
                                    </span>
                                    <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        placeholder="Your heart rate"
                                        value={initialValues.hrRest}
                                        onChange={e => {
                                            if (e.target.value < 0) return
                                            upsertTrainingProfile('hrRest', e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-1">
                        <FontAwesomeIcon
                            style={stepCompletionMap.step3 ? { color: 'green' } : { color: '#818a91' }}
                            className="mr-2"
                            icon={faCheckCircle} size="2x"
                            inverse
                        />
                    </div>

                    <hr className="my-4" style={{ width: '100%', backgroundColor: '#3a3a3a' }} />

                    <div style={{ width: '100%' }}>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon
                                    style={{ color: '#818a91' }}
                                    className="mr-2"
                                    icon={faLink} size="2x"
                                />
                                <h4 style={{ fontWeight: 'bold' }}>Step 4 - Running Fitness</h4>
                            </div>
                        </div>


                        <div className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-between">
                            <div className="d-flex flex-column w-75 w-md-50 text-justify text-md-left" >
                                <p className="my-4">
                                    Perform the 6-minute test to evaluate your current running fitness and create an optimized training plan.
                                </p>
                                <a className="link" style={{ cursor: 'pointer' }} onClick={() => setOpen6Min(true)}>Watch this video to learn how to perform the 6-minute test.</a>
                            </div>

                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <p className="my-4 px-5 text-center text-md-left">
                                    Input your average pace of the test in min per kilometer *
                                </p>

                                <div className="d-flex justify-content-center align-items-center mt-4 mt-md-0">
                                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '30%' }}>
                                        <label className="form-label">Minutes</label>
                                        <div className="input-group mb-3 mr-3" style={{ border: '1px solid #DCDCDC' }}>
                                            <span className="input-group-text" id="basic-addon1">
                                                <FontAwesomeIcon
                                                    style={{ color: '#818a91' }}
                                                    icon={faClock}
                                                    inverse
                                                />
                                            </span>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={initialValues.sixMinTimeTrailMinutes}
                                                onChange={e => {
                                                    if (e.target.value < 0) return
                                                    upsertTrainingProfile('sixMinTimeTrailMinutes', e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '30%' }}>
                                        <label className="form-label">Seconds</label>
                                        <div className="input-group mb-3" style={{ border: '1px solid #DCDCDC' }}>
                                            <span className="input-group-text" id="basic-addon1">
                                                <FontAwesomeIcon
                                                    style={{ color: '#818a91' }}
                                                    icon={faClock}
                                                    inverse
                                                />
                                            </span>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={initialValues.sixMinTimeTrailSeconds}
                                                onChange={e => {
                                                    if (e.target.value < 0) return
                                                    upsertTrainingProfile('sixMinTimeTrailSeconds', e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="text-right mt-5">
                                    <a target='blank' href='https://bannister.coach/minutes-per-mile-to-minutes-per-km-calculator/'>
                                        * Use this calculator to convert from minutes per mile to minutes per kilometer.</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-1">
                        <FontAwesomeIcon
                            style={stepCompletionMap.step4 ? { color: 'green' } : { color: '#818a91' }}
                            className="mr-2"
                            icon={faCheckCircle} size="2x"
                            inverse
                        />
                    </div>

                    <hr className="mt-4" style={{ width: '100%', backgroundColor: '#3a3a3a' }} />

                    <button
                        type="button"
                        className="btn btn-primary btn-lg my-4"
                        style={{ backgroundColor: (!stepCompletionMap.step1 || !stepCompletionMap.step2 || !stepCompletionMap.step3 || !stepCompletionMap.step4) ? '#9F9F9F' : '', border: 0 }}
                        disabled={
                            !stepCompletionMap.step1 || !stepCompletionMap.step2 || !stepCompletionMap.step3 || !stepCompletionMap.step4
                        }
                        onClick={onCreateFirstTrainingPlan}
                    >
                        Create your first training
                    </button>

                    <p className="mb-4">
                        Questions? Email us at diego@bannister.coach
                    </p>
                </div>
            </Container>

            <ModalVideo channel='vimeo' isOpen={isOpenRoutine} videoId="514020099" onClose={() => setOpenRoutine(false)} />
            <ModalVideo channel='vimeo' isOpen={isOpen6Min} videoId="458739495" onClose={() => setOpen6Min(false)} />
            <Footer />
        </>
    )
}