import React, { useContext, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { authStore } from '../../store'

import { STRAVA_CONNECT, CREATE_TRAINING_PROFILE } from '../../graphql/mutations'
import { GET_USER_DETAILS, GET_USER_TRAINING_PROFILE } from '../../graphql/queries'

import CreateProfilePageView from './create-profile-page-view'
import Loader from '../../components/loader'

export default function CreateProfilePageContainer() {
    const { state, dispatch } = useContext(authStore)
    const history = useHistory()

    const [stravaError, setStravaError] = useState()

    const [stepCompletionMap, setStepCompletionMap] = useState({
        step1: false,
        step2: false,
        step3: false,
        step4: false,
    })

    const [birthDay, setBirthDay] = useState('')
    const [sex, setSex] = useState('')
    const [hrRest, setHrRate] = useState('')
    const [sixMinTimeTrailSeconds, setSixMinTimeTrailSec] = useState('')
    const [sixMinTimeTrailMinutes, setSixMinTimeTrailMin] = useState('')

    const [stravaConnectMutation] = useMutation(STRAVA_CONNECT, {
        errorPolicy: 'all'
    })

    const [upsertTrainingProfileMutation] = useMutation(CREATE_TRAINING_PROFILE, {
        errorPolicy: 'all'
    })

    const { data: getUserDetailsData, loading: getUserDetailsLoading } = useQuery(
        GET_USER_DETAILS,
        {
            variables: { userId: state.user.id },
            fetchPolicy: 'network-only'
        }
    )

    const { data: getUserTrainingProfileData, loading: getUserTrainingProfileLoading } = useQuery(
        GET_USER_TRAINING_PROFILE,
        {
            variables: { uid: state.user.id },
            fetchPolicy: 'network-only'
        }
    )

    useEffect(() => {
        const url = new URL(window.location.href)
        const errorParam = url.searchParams.get('error')
        if (errorParam) {
            setStravaError(errorParam)
        }
    }, [])

    useEffect(() => {
        const newStepCompletionMap = { ...stepCompletionMap }

        if (birthDay && sex) {
            newStepCompletionMap.step1 = true
        }

        if (sixMinTimeTrailMinutes && sixMinTimeTrailSeconds) {
            newStepCompletionMap.step4 = true
        } else {
            newStepCompletionMap.step4 = false
        }

        setStepCompletionMap(newStepCompletionMap)

    }, [birthDay, sex, sixMinTimeTrailMinutes, sixMinTimeTrailSeconds])


    useEffect(() => {
        if (!getUserDetailsLoading && getUserDetailsData) {
            if (getUserDetailsData.getUserDetails.stravaIntegrated) {
                setStepCompletionMap({ ...stepCompletionMap, step2: true })
            }
        }
    }, [getUserDetailsData])

    useEffect(() => {
        if (!getUserTrainingProfileLoading && getUserTrainingProfileData) {
            if (getUserTrainingProfileData.getTrainingProfile.birthDay) {
                setBirthDay(getUserTrainingProfileData.getTrainingProfile.birthDay)
            }

            if (getUserTrainingProfileData.getTrainingProfile.sex) {
                setSex(getUserTrainingProfileData.getTrainingProfile.sex)
            }

            if (getUserTrainingProfileData.getTrainingProfile.hrRest) {
                setHrRate(getUserTrainingProfileData.getTrainingProfile.hrRest)
                setStepCompletionMap({ ...stepCompletionMap, step3: true })
            }

            if (getUserTrainingProfileData.getTrainingProfile.sixMinTimeTrailMinutes) {
                setSixMinTimeTrailMin(getUserTrainingProfileData.getTrainingProfile.sixMinTimeTrailMinutes)
            }

            if (getUserTrainingProfileData.getTrainingProfile.sixMinTimeTrailSeconds) {
                setSixMinTimeTrailSec(getUserTrainingProfileData.getTrainingProfile.sixMinTimeTrailSeconds)
            }
        }
    }, [getUserTrainingProfileData])


    function connectStrava() {
        stravaConnectMutation({
            variables: { uid: state.user.id }
        }).then((res) => {
            window.location.replace(res.data.createStravaAuthLink)
        })
    }

    function upsertTrainingProfile(type, value) {
        if (type === 'birthDay') {
            setBirthDay(value)
        }

        if (type === 'sex') {
            setSex(value)
        }

        if (type === 'hrRest') {
            setHrRate(value)
        }

        if (type === 'sixMinTimeTrailSeconds') {
            setSixMinTimeTrailSec(value)
        }

        if (type === 'sixMinTimeTrailMinutes') {
            setSixMinTimeTrailMin(value)
        }

        upsertTrainingProfileMutation({
            variables: {
                trainingProfile: {
                    uid: state.user.id,
                    [type]: value
                }
            }
        }).then(() => {
            if (type === 'hrRest') {
                if (value) {
                    setStepCompletionMap({ ...stepCompletionMap, step3: true })
                } else {
                    setStepCompletionMap({ ...stepCompletionMap, step3: false })
                }
            }
        }).catch(() => {
            if (type === 'birthDay') {
                setBirthDay('')
            }

            if (type === 'sex') {
                setSex('')
            }

            if (type === 'hrRest') {
                setHrRate('')
                setStepCompletionMap({ ...stepCompletionMap, step3: false })
            }

            if (type === 'sixMinTimeTrailSeconds') {
                setSixMinTimeTrailSec('')
            }

            if (type === 'sixMinTimeTrailMinutes') {
                setSixMinTimeTrailMin('')
            }
        })
    }

    function onCreateFirstTrainingPlan() {
        dispatch({ data: { step: 'ONBOARDING_COMPLETED' }, type: 'UPDATE_STEP' })
        history.push('/training-profile-plan-length')
    }


    if (getUserDetailsLoading || getUserTrainingProfileLoading) {
        return <Loader />
    }

    return <CreateProfilePageView
        connectStrava={connectStrava}
        upsertTrainingProfile={upsertTrainingProfile}
        stravaError={stravaError}
        stepCompletionMap={stepCompletionMap}
        onCreateFirstTrainingPlan={onCreateFirstTrainingPlan}
        initialValues={{ birthDay, sex, hrRest, sixMinTimeTrailMinutes, sixMinTimeTrailSeconds }}
    />
}