import React from 'react'
import PropTypes from 'prop-types'

import styles from './user-profile-page.module.scss'

const SectionTitle = ({ title }) => {
    return (
        <div className={styles['section-card']}>
            <p className={styles['section-title']}>{title}</p>
        </div>
    )
}

SectionTitle.propTypes = {
    title: PropTypes.string
}

export default SectionTitle
