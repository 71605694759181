import React from 'react'
import PropTypes from 'prop-types'

import BackgroundImageContainer from '../components/background-image-container'
import Title from '../components/title'
import Subtitle from '../components/subtitle'

import pricingImage from '../assets/images/pricing-background.png'

const AccountLayout = ({ children, title, subtitle }) => {
    return (
        <BackgroundImageContainer>
            {title && <Title text={title} />}
            {subtitle && <Subtitle text={subtitle} />}
            {children}
        </BackgroundImageContainer>
    )
}

AccountLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    backgroundImage: PropTypes.string
}

export default AccountLayout
