import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'

import BackgroundImageContainer from '../components/background-image-container'
import Title from '../components/title'
import Subtitle from '../components/subtitle'
import Header from './header'
import Footer from './footer'

import stravaImage from '../assets/images/strava-background.png'

const TrainingProfileLayout = ({
    children,
    title,
    subtitle,
    logoSrc,
    progress,
    action,
    disabled,
    buttonText,
    loader,
    center
}) => {
    return (
        <>
            <Header />
            <BackgroundImageContainer
                imageSrc={stravaImage}
                logoSrc={logoSrc || 'none'}
                margin
                center={center}
                progress={!loader && progress}
            >
                {title && <Title text={title} />}
                {subtitle && <Subtitle text={subtitle} />}
                {children}

                {action && (
                    <button
                        type="button"
                        className="btn btn-primary btn-lg my-4 width100 mt40"
                        style={{ border: 0 }}
                        disabled={disabled}
                        onClick={() => action()}
                    >
                        {buttonText || 'Continue'}
                    </button>
                )}
            </BackgroundImageContainer>
            <Footer />
        </>
    )
}

TrainingProfileLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    logoSrc: PropTypes.string,
    progress: PropTypes.number,
    action: PropTypes.func,
    disabled: PropTypes.bool,
    buttonText: PropTypes.string,
    loader: PropTypes.bool,
    center: PropTypes.bool
}

export default TrainingProfileLayout
