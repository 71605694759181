import React, { useState } from 'react'
import PropTypes from 'prop-types'
import addDays from 'date-fns/addDays'

import DatePicker from '../../../components/datepicker'

import styles from './profile.module.scss'

const ProfileRaceDate = ({ raceDate }) => {
    const [time, setTime] = useState(new Date(raceDate))

    return (
        <div className={styles.card}>
            <p className={styles.label}>Race date</p>
            <div className={`${styles['right-wrapper']} custom-date`}>
                <DatePicker
                    time={time}
                    onTimeChange={(e) => setTime(e)}
                    minDate={addDays(new Date(), 7)}
                    readOnly
                />
            </div>
        </div>
    )
}

ProfileRaceDate.propTypes = {
    raceDate: PropTypes.string
}

export default ProfileRaceDate
