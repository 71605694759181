import PricingPageRoute from './pricing-page'
import PaymentPageRoute from './payment-page'
import CreateAccountPageRoute from './create-account-page'
import CreateProfilePage from './create-profile-page'
import TrainingProfileRaceDatePageRoute from './training-profile-race-date-page'
import TrainingProfileSpeedUnitRoute from './training-profile-speed-unit-page'
import TrainingProfileProgramPageRoute from './training-profile-program-page'
import TrainingProfilePlanLengthRoute from './training-profile-plan-lenght-page'
import LoginPageRoute from './login-page'
import TrainingPlanPageRoute from './training-plan-page'
import UserProfilePageRoute from './user-profile-page'
import AdministrationPageRoute from './administration-page'

export default {
    PricingPageRoute,
    PaymentPageRoute,
    CreateAccountPageRoute,
    CreateProfilePage,
    TrainingProfileRaceDatePageRoute,
    TrainingProfileProgramPageRoute,
    TrainingProfileSpeedUnitRoute,
    TrainingProfilePlanLengthRoute,
    LoginPageRoute,
    TrainingPlanPageRoute,
    UserProfilePageRoute,
    AdministrationPageRoute
}
