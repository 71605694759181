import gql from 'graphql-tag'

export const CREATE_USER_ACCOUNT = gql`
    mutation CreateUserAccount($userAccount: UserAccountInput) {
        createUserAccount(userAccount: $userAccount) {
            firstName
            id
            lastName
            email
            step
            idToken
            refreshToken
            role
        }
    }
`

export const LOGIN = gql`
    mutation LoginMutation($credentials: LoginInput) {
        login(credentials: $credentials) {
            email
            id
            firstName
            lastName
            step
            idToken
            refreshToken
            role
        }
    }
`
export const PAYMENT = gql`
    mutation sendPaymentMutation($paymentInfo: PaymentInfoInput) {
        sendPayment(paymentInfo: $paymentInfo) {
            payment_intent_client_secret
            requires_action
            success
        }
    }
`
export const STRAVA_CONNECT = gql`
    mutation CreateStravaAuthLinkMutation($uid: String!) {
        createStravaAuthLink(uid: $uid)
    }
`

export const CREATE_TRAINING_PROFILE = gql`
    mutation UpsertTrainingProfile($trainingProfile: TrainingProfileInput!) {
        upsertTrainingProfile(trainingProfile: $trainingProfile)
    }
`

export const CREATE_TRAINING_PLAN = gql`
    mutation GenerateTrainingPlan($trainingProfile: TrainingProfileInput!) {
        generateTrainingPlan(trainingProfile: $trainingProfile)
    }
`


export const UPDATE_HR = gql`
    mutation UpdateHRMutation($updateHrHr: Int!, $updateHrUid: String!) {
        updateHR(hr: $updateHrHr, uid: $updateHrUid) {
            hr
            hrrZones
        }
    }
`

export const UPDATE_SIX_MIN_TRAIL = gql`
    mutation UpdateSixMinTimeTrailMutation(
        $updateSixMinTimeTrailMin: Int!
        $updateSixMinTimeTrailSec: Int!
        $updateSixMinTimeTrailUid: String!
    ) {
        updateSixMinTimeTrail(
            min: $updateSixMinTimeTrailMin
            sec: $updateSixMinTimeTrailSec
            uid: $updateSixMinTimeTrailUid
        ) {
            min
            paceZones
            sec
        }
    }
`

export const REFRESH_ID_TOKEN = gql`
    mutation RefreshIdToken($refreshIdToken: String!) {
        refreshIdToken(refreshIdToken: $refreshIdToken) {
            accessToken
            refreshToken
        }
    }
`

export const GET_COUPON_DETAILS = gql`
    mutation VerifyUserIdTokenMutation($verifyCouponCoupon: String!) {
        verifyCoupon(coupon: $verifyCouponCoupon) {
            isValid
            type
            value
        }
    }
`
