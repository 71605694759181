import React, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import { faHeartbeat } from '@fortawesome/free-solid-svg-icons'
import { authStore } from '../../../store'

import { UPDATE_HR } from '../../../graphql/mutations'
import { GET_USER_TRAINING_PROFILE } from '../../../graphql/queries'

import Input from '../../../components/input'

import styles from './profile.module.scss'

const ProfileRestingHr = ({ restingHr }) => {
    const [responseMessage, setResponseMessage] = useState()
    const [error, setError] = useState(false)
    const [display, setDisplay] = useState(false)
    const { state } = useContext(authStore)

    const initialValues = {
        restingHr
    }

    const [updateHRMutation] = useMutation(UPDATE_HR, {
        errorPolicy: 'all'
    })

    function updateHR(values, resetForm) {
        updateHRMutation({
            variables: {
                updateHrHr: values.restingHr,
                updateHrUid: state.user.id
            },
            refetchQueries: [
                { query: GET_USER_TRAINING_PROFILE, variables: { uid: state.user.id } }
            ]
        }).then((res) => {
            resetForm()
            setDisplay(true)
            setInterval(() => {
                setDisplay(false)
            }, 3000)

            if (res.errors) {
                setError(true)
                setResponseMessage(res.errors[0].message)
            } else {
                setResponseMessage('Resting HR was updated successfully!')
            }
        })
    }

    return (
        <div>
            <Formik
                enableReinitialize
                validateOnBlur={false}
                initialValues={initialValues}
                validate={(values) => {
                    const errors = {}

                    if (!values.restingHr && values.restingHr !== 0) {
                        errors.restingHr = 'Required'
                    }

                    return errors
                }}
                onSubmit={(values, { resetForm }) =>
                    // eslint-disable-next-line
                    updateHR(values, resetForm)}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    dirty,
                    isValid,
                    isSubmitting
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className={styles.card}>
                            <p className={styles.label}>Resting HR</p>
                            <div className={styles['right-wrapper']}>
                                <Input
                                    icon={faHeartbeat}
                                    placeholder="72"
                                    name="restingHr"
                                    type="number"
                                    value={values.restingHr}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.restingHr}
                                    error={errors.restingHr}
                                    border
                                />
                                <Button
                                    variant="outline-primary"
                                    className="inline-button"
                                    type="submit"
                                    disabled={isSubmitting || !(dirty && isValid)}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                        {responseMessage && (
                            <p
                                className={styles.response}
                                style={{
                                    color: error ? '#f45628' : '#00D88D',
                                    display: display === true ? 'block' : 'none'
                                }}
                            >
                                {responseMessage}
                            </p>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

ProfileRestingHr.propTypes = {
    restingHr: PropTypes.number
}

export default ProfileRestingHr
