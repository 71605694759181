import React, { useContext, useState } from 'react'

import { CountryDropdown } from 'react-country-region-selector'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'

import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import {
    faCreditCard,
    faCalendarAlt,
    faMapMarkerAlt,
    faUser,
    faLock,
    faAddressCard,
    faMapMarkedAlt,
    faCity,
    faPercent
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Input from '../../components/input'

import { authStore } from '../../store'

import { GET_COUPON_DETAILS } from '../../graphql/mutations'

import styles from './payment-page.module.scss'

const styleOptions = {
    style: {
        base: {
            color: '#222222',
            fontWeight: 400,
            fontFamily: 'Montserrat',
            fontSize: '16px'
        },
        '::placeholder': {
            color: '#aaaaaa'
        }
    }
}

const PaymentForm = ({ error, savePayment, countryData, city, setCouponData }) => {
    const [country, setCountry] = useState(countryData.value)
    const [stripeTokenError, setStripeTokenError] = useState('')
    const { state } = useContext(authStore)

    const stripe = useStripe()
    const elements = useElements()

    const [getCouponDetails] = useMutation(GET_COUPON_DETAILS, {
        errorPolicy: 'all'
    })

    const initialValues = {
        address: '',
        fullName: `${state.user.firstName} ${state.user.lastName}`,
        postalCode: '',
        city,
        coupon: ''
    }

    const membership = localStorage.getItem('membership')

    function selectCountry(val) {
        setCountry(val)
    }

    function checkCoupon(e) {
        const couponId = e.target.value
        if (couponId) {
            getCouponDetails({
                variables: { verifyCouponCoupon: couponId }
            }).then((res) => {
                if (res.data && res.data.verifyCoupon && res.data.verifyCoupon.isValid) {
                    setCouponData(res.data.verifyCoupon)
                } else {
                    setCouponData(null)
                }
            })
        } else {
            setCouponData(null)
        }
    }

    return (
        <Formik
            validateOnBlur={false}
            initialValues={initialValues}
            validate={(values) => {
                const errors = {}

                if (!values.fullName) {
                    errors.fullName = 'Required'
                } else if (!/^[a-zA-Z -]*$/g.test(values.fullName)) {
                    errors.fullName = 'Only letters are allowed'
                }

                if (!values.postalCode) {
                    errors.postalCode = 'Required'
                }

                if (!values.address) {
                    errors.address = 'Required'
                }

                if (!values.city) {
                    errors.city = 'Required'
                }

                return errors
            }}
            onSubmit={(values, actions) => {
                stripe
                    .createToken(elements.getElement(CardNumberElement))
                    .then((token) => {
                        if (token.error) {
                            setStripeTokenError(token.error.message)
                            return actions.setSubmitting(false)
                        }

                        const toSave = {
                            ...values,
                            subscriptionType: state.user.membership,
                            stripeToken: token.token.id,
                            email: state.user.email,
                            country
                        }
                        // eslint-disable-next-line
                        savePayment(toSave, stripe)
                    })
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
                isValid,
                isSubmitting
            }) => (
                <Form className={styles['payment-form']} onSubmit={handleSubmit}>
                    <Form.Row className={styles['payment-form-row']}>
                        <Form.Group className={styles['payment-form-group']}>
                            <InputGroup className={styles['input-border']} as={Col}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faCreditCard} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <CardNumberElement
                                    className="form-control custom-input"
                                    options={styleOptions}
                                />
                            </InputGroup>
                            <div className={styles['inline-group']}>
                                <InputGroup
                                    className={`${styles['input-border']} mr10`}
                                    as={Col}
                                >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <CardExpiryElement
                                        className="form-control custom-input"
                                        options={styleOptions}
                                    />
                                </InputGroup>
                                <InputGroup className={styles['input-border']} as={Col}>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faLock} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <CardCvcElement
                                        className="form-control custom-input"
                                        options={styleOptions}
                                    />
                                </InputGroup>
                            </div>
                            <Input
                                icon={faUser}
                                placeholder="Cardholder Name"
                                name="fullName"
                                type="text"
                                value={values.fullName}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.fullName}
                                error={errors.fullName}
                                border
                            />
                            <Input
                                icon={faMapMarkerAlt}
                                placeholder="Postal code"
                                name="postalCode"
                                type="text"
                                value={values.postalCode}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.postalCode}
                                error={errors.postalCode}
                                border
                            />
                            <Input
                                icon={faAddressCard}
                                placeholder="Address"
                                name="address"
                                type="text"
                                value={values.address}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.address}
                                error={errors.address}
                                border
                            />
                            <Input
                                icon={faCity}
                                placeholder="City"
                                name="city"
                                type="text"
                                value={values.city}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.city}
                                error={errors.city}
                                border
                            />
                            <InputGroup className={styles['input-border']} as={Col}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>

                                <CountryDropdown
                                    className="form-control"
                                    value={country}
                                    name="country"
                                    onChange={(val) => selectCountry(val)}
                                    valueType="short"
                                    priorityOptions={['CA', 'US', 'AU', 'GB']}
                                />
                                {touched && errors.country ? (
                                    <div className="error-message">{errors.country}</div>
                                ) : null}
                            </InputGroup>

                            {membership === 'YEAR' && (
                                <Input
                                    icon={faPercent}
                                    placeholder="Coupon code"
                                    name="coupon"
                                    type="text"
                                    value={values.coupon}
                                    handleChange={(e) => {
                                        handleChange(e)
                                        checkCoupon(e)
                                    }}
                                    handleBlur={handleBlur}
                                    touched={touched.coupon}
                                    error={errors.coupon}
                                    border
                                />
                            )}
                        </Form.Group>
                    </Form.Row>

                    {(touched && error) || (stripeTokenError) ? (
                        <div className="error-message justify-content-center">{error || stripeTokenError}</div>
                    ) : null}

                    <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting || !(dirty && isValid)}
                        className="custom-button width100"
                        style={{ marginTop: '30px' }}
                    >
                        Complete purchase
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

PaymentForm.propTypes = {
    error: PropTypes.string,
    savePayment: PropTypes.func,
    countryData: PropTypes.objectOf(PropTypes.any),
    city: PropTypes.string,
    setCouponData: PropTypes.func
}

export default PaymentForm
