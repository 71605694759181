import React from 'react'
import PropTypes from 'prop-types'

import CustomModal from '../../components/modal'
import TrainingDetailsCard from './training-details-card'

import { raceDayMessage, formatStringWithLink } from '../../constants/utils'

const TrainingDetailsModal = ({ open, closeModal, selectedDay, hrrZones, paceZones }) => {
    function renderSubtitle() {
        let subtitle
        if (selectedDay && selectedDay.type === 'Race Date') {
            subtitle = raceDayMessage
        }
        if (selectedDay && selectedDay.parts) {
            subtitle = selectedDay.parts.map((part) => {
                if (part.commentOnly && part.commentOnly === true) {
                    return formatStringWithLink(part.comment)
                }
                return ''
            })[0]
        }
        return subtitle
    }
    return (
        <CustomModal
            open={open}
            title={selectedDay && selectedDay.type}
            subtitle={renderSubtitle()}
            onClose={closeModal}
        >
            {selectedDay &&
                selectedDay.parts &&
                selectedDay.parts.map((part) => {
                    if (!part.rest && !part.commentOnly) {
                        return (
                            <TrainingDetailsCard
                                key={part.comment}
                                title={part.type ? part.type : 'Main set'}
                                subtitle={part.comment}
                                time={part.time}
                                zone={part.zoneNum}
                                pace={part.pace}
                                zoneValue={part.pace
                                    ? paceZones[part.zoneNum]
                                    : hrrZones[part.zoneNum]}
                            />
                        )
                    }
                })}
        </CustomModal>
    )
}

TrainingDetailsModal.propTypes = {
    closeModal: PropTypes.func,
    open: PropTypes.bool,
    selectedDay: PropTypes.objectOf(PropTypes.any),
    hrrZones: PropTypes.objectOf(PropTypes.any)
}

export default TrainingDetailsModal
