import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { parseDateWithoutTimezone } from '../../constants/utils'

import DayCard from './day-card'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styles from './training-plan-page.module.scss'

const SlickNext = ({ currentSlide, slideCount, ...props }) => (
    <FontAwesomeIcon {...props} icon={faChevronRight} />
)

SlickNext.propTypes = {
    currentSlide: PropTypes.number,
    slideCount: PropTypes.number
}

const SlickPrev = ({ currentSlide, slideCount, ...props }) => (
    <FontAwesomeIcon {...props} icon={faChevronLeft} />
)

SlickPrev.propTypes = {
    currentSlide: PropTypes.number,
    slideCount: PropTypes.number
}

const TrainingSlider = ({ openModal, trainingPlan, firstSlide }) => {
    const [week, setWeek] = useState(1)
    const [date, setDate] = useState()

    useEffect(() => {
        setDate(format(parseDateWithoutTimezone(trainingPlan[1][1].date), 'MMM yyyy'))
    }, [trainingPlan])

    function nextClick(currentSlide, nextSlide) {
        let slideNo
        if (nextSlide > currentSlide) {
            slideNo = nextSlide + (nextSlide - currentSlide)
        } else if (nextSlide < currentSlide) {
            slideNo = nextSlide - (nextSlide - currentSlide)
        }

        setWeek(Math.ceil(slideNo / 7))
        Object.keys(trainingPlan[week]).map((day) => {
            return setDate(
                format(parseDateWithoutTimezone(trainingPlan[week][day].date), 'MMM yyyy')
            )
        })
    }

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 7,
        initialSlide: firstSlide,
        beforeChange: nextClick,
        nextArrow: <SlickNext />,
        prevArrow: <SlickPrev />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    }

    return (
        <>
            <div className={styles['carousel-title']}>
                <p>
                    <span className={styles.week}>
                        {`Week ${week} / ${Object.keys(trainingPlan).length}`}
                    </span>
                    <span className={styles.month}>{date}</span>
                </p>
            </div>
            <Slider {...settings}>
                {Object.keys(trainingPlan).map((key) => {
                    return Object.keys(trainingPlan[key]).map((k) => {
                        let trainingInfo = trainingPlan[key][k]
                        let trainingInfoParsed

                        if (trainingInfo.parts) {
                            trainingInfo.parts.map((partWithRest) => {
                                if (partWithRest.rest && partWithRest.rest === true) {
                                    const partsFormatted = trainingInfo.parts.map(
                                        (part) => {
                                            const addRestTimeCondition =
                                                !part.rest && part.type === 'Main Set'
                                            return {
                                                ...part,
                                                ...(addRestTimeCondition && {
                                                    time: part.time + partWithRest.time
                                                })
                                            }
                                        }
                                    )
                                    trainingInfoParsed = {
                                        ...trainingInfo,
                                        parts: partsFormatted
                                    }
                                    return trainingInfoParsed
                                }
                            })
                        }

                        if (trainingInfoParsed && trainingInfoParsed !== undefined) {
                            trainingInfo = trainingInfoParsed
                        }

                        const hasAction =
                            trainingInfo.type === 'Race Date' ||
                            trainingInfo.type === 'Comment'
                        return (
                            <DayCard
                                trainingInfo={trainingInfo}
                                date={trainingInfo.date}
                                title={trainingInfo.type}
                                zone={trainingInfo.zone}
                                time={trainingInfo.totalTime}
                                // eslint-disable-next-line
                                color={trainingInfo.completed ? '#00D88D' : '#aaaaaa'}
                                action={(trainingInfo.parts || hasAction) && openModal}
                                off={trainingInfo.type === 'Off'}
                            />
                        )
                    })
                })}
            </Slider>
        </>
    )
}

TrainingSlider.propTypes = {
    openModal: PropTypes.func,
    trainingPlan: PropTypes.objectOf(PropTypes.any),
    firstSlide: PropTypes.number
}

export default TrainingSlider
