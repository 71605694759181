import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import pricingCardCurve from '../../assets/images/grid.png'
import svgCurve from '../../assets/images/pricing-card.svg'

import styles from './pricing-page.module.scss'

const PricingCard = ({ type }) => {
    return (
        <Card className={styles['card-wrapper']}>
            <div className={styles['pink-background']}>
                <p className={styles['card-title']}>
                    {type === 'monthly' ? 'Monthly' : 'Annually'}
                </p>
                <p className={styles['card-amount']}>
                    <sup className={styles.dollar}>$</sup>
                    <span className={styles.amount}>
                        {type === 'monthly' ? '40' : '28'}
                    </span>
                    <span className={styles['card-per']}>
                        {type === 'monthly' ? '/ month' : '/ month*'}
                    </span>
                </p>
                {type === 'annual' && (
                    <span className={styles['card-mention']}>* billed annually</span>
                )}
                <img className={styles.curve} src={pricingCardCurve} alt="curve" />
                <img className={styles['svg-curve']} src={svgCurve} alt="svg curve" />
            </div>

            <Card.Body className={styles['card-body-wrapper']}>
                {type === 'monthly' ? (
                    <ul className={styles.list}>
                        <li>Unlimited Tailor-Made Programs</li>
                        <li>Training programs from 5k to Marathon</li>
                        <li>Onboarding session with a coach</li>
                        <li>Unlimited real-time training adjustments</li>
                        <li>Clear and easy to follow directions</li>
                        <li>No Setup Fees</li>
                        <li>30-day money back guarantee</li>
                        <li>
                            {' '}
                            <span className="strike">
                                Monthly Q&A session with a Coach
                            </span>
                        </li>
                    </ul>
                ) : (
                    <ul className={styles.list}>
                        <li>Unlimited Tailor-Made Programs</li>
                        <li>Training programs from 5k to Marathon</li>
                        <li>Onboarding session with a coach</li>
                        <li>Unlimited real-time training adjustments</li>
                        <li>Clear and easy to follow directions</li>
                        <li>No Setup Fees</li>
                        <li>30-day money back guarantee</li>
                        <li>Monthly Q&A session with a Coach</li>
                    </ul>
                )}

                <div className="center">
                    <Link to="/create-account">
                        <Button variant="primary" className="custom-button">
                            Get started!
                        </Button>
                    </Link>
                </div>
            </Card.Body>
        </Card>
    )
}

PricingCard.propTypes = {
    type: PropTypes.string
}

export default PricingCard
