import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isToday } from 'date-fns'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { parseDateWithoutTimezone } from '../../constants/utils'

import NavigationBar from '../../layout/navigation-bar'
import Footer from '../../layout/footer'
import Banner from '../../components/banner'

import TrainingSlider from './training-slider'
import TrainingZones from './training-zones'
import TrainingRaceDetails from './training-race-details'
import TrainingDetailsModal from './training-details-modal'

import styles from './training-plan-page.module.scss'

const TrainingPlanPageView = ({ trainingPlanData }) => {
    const [selectedDay, setSelectedDay] = useState()
    const [open, setIsOpened] = useState(false)

    function openModal(data) {
        setSelectedDay(data)
        setIsOpened(true)
    }

    function closeModal() {
        setIsOpened(false)
    }
    const { hrrZones, raceDate, trainingPlan, paceZones } = trainingPlanData

    let initialSlide = 1
    if (trainingPlan && Object.keys(trainingPlan).length) {
        Object.keys(trainingPlan).forEach((key) => {
            Object.keys(trainingPlan[key]).forEach((k) => {
                if (
                    isToday(parseDateWithoutTimezone(new Date(trainingPlan[key][k].date)))
                ) {
                    if (key > 1) {
                        initialSlide = parseInt(key - 1, 10) * 7 + parseInt(k, 10)
                    } else {
                        initialSlide = parseInt(k, 10)
                    }
                }
            })
        })
    }

    return (
        <div className={styles['training-plan']}>
            <NavigationBar />
            <Banner
                title="No excuses, only results."
                subtitle="Let yourself be guided in the right way and get the results you want."
            />
            <TrainingRaceDetails raceDate={raceDate} />

            {trainingPlan && Object.keys(trainingPlan).length ? (
                <div className={styles.container}>
                    <ProgressBar variant="primary" now={0} className={styles.progress} />
                    <TrainingSlider
                        openModal={openModal}
                        trainingPlan={trainingPlan}
                        firstSlide={initialSlide - 1}
                    />
                </div>
            ) : (
                <div className={styles.container}>
                    The training plan could not be generated
                </div>
            )}
            <TrainingZones hrrZones={hrrZones} paceZones={paceZones} />
            <Footer />

            <TrainingDetailsModal
                open={open}
                hrrZones={hrrZones}
                paceZones={paceZones}
                closeModal={closeModal}
                selectedDay={selectedDay}
            />
        </div>
    )
}

TrainingPlanPageView.propTypes = {
    trainingPlanData: PropTypes.objectOf(PropTypes.any)
}

export default TrainingPlanPageView
