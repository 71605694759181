import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const tooltip = (text, display) => (
    <Tooltip
        id="tooltip"
        style={{
            display: display ? '' : 'none'
        }}
    >
        {text}
    </Tooltip>
)

const CustomOverlay = ({ children, text, display }) => {
    return (
        <OverlayTrigger placement="top" overlay={tooltip(text, display)}>
            {children}
        </OverlayTrigger>
    )
}

CustomOverlay.propTypes = {
    children: PropTypes.node.isRequired,
    text: PropTypes.string,
    display: PropTypes.bool
}

export default CustomOverlay
