import React from 'react'
import PropTypes from 'prop-types'

import bannisterLogoPink from '../assets/images/logo-pink.png'

import styles from './common.module.scss'

const Logo = ({ logoSrc }) => {
    return (
        <a className={`${styles.logo} ${logoSrc && styles.strava}`} href="/">
            <img src={logoSrc || bannisterLogoPink} alt="logo" />
        </a>
    )
}

Logo.propTypes = {
    logoSrc: PropTypes.string
}

export default Logo
