import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import isToday from 'date-fns/isToday'

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { parseDateWithoutTimezone } from '../../constants/utils'

import styles from './training-plan-page.module.scss'

const DayCard = ({
    date,
    color = '#aaaaaa',
    title,
    zone,
    time = '',
    off,
    action,
    trainingInfo
}) => {
    const selected = isToday(parseDateWithoutTimezone(date))

    return (
        <div
            className={styles.card}
            style={{
                background: off ? '#F0F0F0 0% 0% no-repeat padding-box' : '',
                border: selected ? '1px solid #FD1160' : '',
                // opacity: !selected ? '0.7' : '',
                cursor: selected ? 'pointer' : ''
            }}
            onClick={() => action && action(trainingInfo)}
            onKeyUp={() => action && action(trainingInfo)}
            role="button"
            tabIndex={0}
        >
            <div className={styles['card-header']}>
                <div>
                    <span className={styles.month}>
                        {format(parseDateWithoutTimezone(date), 'ccc')}{' '}
                    </span>
                    <span className={styles.day}>
                        {format(parseDateWithoutTimezone(date), 'dd')}
                    </span>
                </div>
                <div>
                    <FontAwesomeIcon icon={faCheckCircle} color={color} />
                </div>
            </div>
            <div>
                <p className={styles['card-title']}>{off ? 'Off' : title}</p>
            </div>
            {!off && (
                <div>
                    <div className={styles.flex}>
                        {zone !== 'undefined' && (zone || zone !== undefined) && (
                            <span className={styles.zone}>{`Z${zone}`}</span>
                        )}
                    </div>
                    <span className={styles.time}>{time}</span>
                </div>
            )}
        </div>
    )
}

DayCard.propTypes = {
    date: PropTypes.string,
    trainingInfo: PropTypes.objectOf(PropTypes.any),
    title: PropTypes.string,
    zone: PropTypes.string,
    time: PropTypes.number,
    color: PropTypes.string,
    off: PropTypes.bool,
    action: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
}

export default DayCard
