import React from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './common.module.scss'

const Input = ({
    icon,
    placeholder,
    name,
    type,
    value,
    handleChange,
    handleBlur,
    touched,
    error,
    border
}) => {
    return (
        <InputGroup className={border ? styles['input-border'] : ''}>
            <InputGroup.Prepend>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={icon} />
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                type={type}
                min="0"
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched && error ? 'error' : null}
            />
            {touched && error ? <div className="error-message">{error}</div> : null}
        </InputGroup>
    )
}

Input.propTypes = {
    icon: PropTypes.objectOf(PropTypes.any),
    placeholder: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    touched: PropTypes.bool,
    error: PropTypes.string,
    border: PropTypes.bool
}

export default Input
