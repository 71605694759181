import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const ChangingProgressProvider = ({ children, values }) => {
    const [valuesIndex, setValueIndex] = useState(0)

    useEffect(() => {
        let interval
        if (values[valuesIndex] !== 100) {
            interval = setInterval(() => {
                setValueIndex((valuesIndex + 1) % values.length)
            }, 500)
        }
        return () => clearInterval(interval)
    }, [valuesIndex])

    return <>{children(values[valuesIndex])}</>
}

ChangingProgressProvider.propTypes = {
    children: PropTypes.node.isRequired,
    values: PropTypes.arrayOf(PropTypes.number)
}

export default ChangingProgressProvider
