import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'

import Loader from '../../components/loader'

import { authStore } from '../../store'
import { GET_USER_TRAINING_PROFILE } from '../../graphql/queries'

import { sixMinEstimateParam1, sixMinEstimateParam2 } from '../../constants/utils'

import UserProfilePageView from './user-profile-page-view'

export default function UserProfilePageContainer() {
    const { state } = useContext(authStore)

    const { data: userProfileResponse, loading: fetchingUserProfile } = useQuery(
        GET_USER_TRAINING_PROFILE,
        {
            variables: { uid: state.user.id },
            fetchPolicy: 'network-only'
        }
    )

    if (fetchingUserProfile) {
        return <Loader />
    }

    function calculateEstimate(minutes, seconds, trainingPlanLength) {
        const sixMinPaceSecondsToDecimal = seconds / 60

        return (
            ((minutes + sixMinPaceSecondsToDecimal) *
                sixMinEstimateParam1[trainingPlanLength] *
                sixMinEstimateParam2[trainingPlanLength]) /
            1440
        )
    }

    return (
        <UserProfilePageView
            calculateEstimate={calculateEstimate}
            userProfile={userProfileResponse.getTrainingProfile}
        />
    )
}
