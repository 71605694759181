import React from 'react'

import SectionTitle from '../section-title'

import styles from './subscription.module.scss'

const Subscription = () => {
    return (
        <div>
            <SectionTitle title="Subscriptions" />

            <div className={styles.card}>
                <div>
                    <p>
                        If you need more details or you have questions about your
                        subscription, please contact us at{' '}
                        <a href="mailto:test@gmail.com">diego@bannister.coach</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Subscription
