import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'

import { parseDateWithoutTimezone } from '../../constants/utils'

import NavigationBar from '../../layout/navigation-bar'
import ProfileNavigation from './profile-navigation'
import Footer from '../../layout/footer'

import { authStore } from '../../store'

import styles from './user-profile-page.module.scss'

const UserProfilePageView = ({ calculateEstimate, userProfile }) => {
    const { state } = useContext(authStore)
    const { sixMinTimeTrails, trainingPlanLength } = userProfile

    function formatDecimal(decimalDays) {
        const daysToHours = decimalDays * 24
        const hours = Math.floor(daysToHours)
        const minInHour = (daysToHours % 1) * 60
        const minutes = Math.floor(minInHour)
        const seconds = Math.round((minInHour % 1) * 60)

        return `${hours}:${minutes.toString().length > 1 ? minutes : `0${minutes}`}:${
            seconds.toString().length > 1 ? seconds : `0${seconds}`
        }`
    }

    function formatTime(minutes, seconds) {
        return `${minutes.toString().length > 1 ? minutes : `0${minutes}`}:${
            seconds.toString().length > 1 ? seconds : `0${seconds}`
        }`
    }

    return (
        <div className={styles['user-profile']}>
            <NavigationBar />
            <div className={styles.banner}>
                <div className={styles.user}>
                    <div>
                        <h1 className={styles.title}>
                            {`${state.user.firstName} ${state.user.lastName}`}
                        </h1>
                    </div>
                </div>
                {sixMinTimeTrails.length && (
                    <div>
                        {sixMinTimeTrails.length >= 1 && (
                            <div>
                                {trainingPlanLength !== 'build-up' && (
                                    <>
                                        <p className={styles.time}>
                                            {formatDecimal(
                                                calculateEstimate(
                                                    sixMinTimeTrails[
                                                        sixMinTimeTrails.length - 1
                                                    ].minutes,
                                                    sixMinTimeTrails[
                                                        sixMinTimeTrails.length - 1
                                                    ].seconds,
                                                    trainingPlanLength
                                                )
                                            )}
                                        </p>

                                        <p className={styles.distance}>
                                            {trainingPlanLength} estimated time based on
                                            6-minute test
                                        </p>
                                    </>
                                )}
                            </div>
                        )}
                        {sixMinTimeTrails.length > 1 && (
                            <div className={styles['six-test-wrapper']}>
                                {sixMinTimeTrails.length > 1 && (
                                    <div className={styles.previous}>
                                        <p className={styles.time}>
                                            {formatTime(
                                                sixMinTimeTrails[
                                                    sixMinTimeTrails.length - 2
                                                ].minutes,
                                                sixMinTimeTrails[
                                                    sixMinTimeTrails.length - 2
                                                ].seconds
                                            )}
                                        </p>

                                        <p className={styles.time}>
                                            {sixMinTimeTrails[sixMinTimeTrails.length - 2]
                                                .createdAt &&
                                                format(
                                                    parseDateWithoutTimezone(
                                                        new Date(
                                                            sixMinTimeTrails[
                                                                sixMinTimeTrails.length -
                                                                    2
                                                            ].createdAt
                                                        )
                                                    ),
                                                    'dd/MM/yyyy'
                                                )}
                                        </p>

                                        <p className={styles.distance}>
                                            previous 6-minute test
                                        </p>
                                    </div>
                                )}
                                <div>
                                    <p className={styles.time}>
                                        {formatTime(
                                            sixMinTimeTrails[sixMinTimeTrails.length - 1]
                                                .minutes,

                                            sixMinTimeTrails[sixMinTimeTrails.length - 1]
                                                .seconds
                                        )}
                                    </p>

                                    <p className={styles.time}>
                                        {sixMinTimeTrails[sixMinTimeTrails.length - 1]
                                            .createdAt &&
                                            format(
                                                parseDateWithoutTimezone(
                                                    new Date(
                                                        sixMinTimeTrails[
                                                            sixMinTimeTrails.length - 1
                                                        ].createdAt
                                                    )
                                                ),
                                                'dd/MM/yyyy'
                                            )}
                                    </p>

                                    <p className={styles.distance}>
                                        current 6-minute test
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={styles.wrapper}>
                <ProfileNavigation userProfile={userProfile} />
            </div>

            <Footer />
        </div>
    )
}

UserProfilePageView.propTypes = {
    calculateEstimate: PropTypes.func,
    userProfile: PropTypes.objectOf(PropTypes.any)
}

export default UserProfilePageView
