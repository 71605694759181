import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { authStore } from '../../store'

import { faRunning } from '@fortawesome/free-solid-svg-icons'

import TrainingProfileLayout from '../../layout/training-profile-layout'
import GenericCard from '../../components/generic-card'

import styles from './training-profile-plan-length.module.scss'

const TrainingProfilePlanLengthPageView = () => {
    const history = useHistory()
    const { dispatch } = useContext(authStore)

    const [planLength, setPlanLength] = useState('21k')

    function savePlanLength() {
        const trainingProfile = {}
        trainingProfile.trainingPlanLength = planLength
        localStorage.setItem('training-profile', JSON.stringify(trainingProfile))
        dispatch({ data: { step: 'PLAN_LENGTH_SELECTED' }, type: 'UPDATE_STEP' })
        history.push('/training-profile-race-date')
    }

    return (
        <TrainingProfileLayout
            title="What distance do you want to train for?"
            subtitle="Select below"
            progress={25}
            disabled={!planLength}
            action={savePlanLength}
        >
            <div className={styles.cards}>
                <GenericCard
                    icon={faRunning}
                    selected={planLength === 'build-up'}
                    gender="Build Up"
                    action={() => setPlanLength('build-up')}
                />
                <GenericCard
                    icon={faRunning}
                    selected={planLength === '5k'}
                    gender="5k"
                    action={() => setPlanLength('5k')}
                />
                <GenericCard
                    icon={faRunning}
                    selected={planLength === '10k'}
                    gender="10k"
                    action={() => setPlanLength('10k')}
                />
                <GenericCard
                    icon={faRunning}
                    selected={planLength === '21k'}
                    gender="Half Marathon"
                    action={() => setPlanLength('21k')}
                />
                <GenericCard
                    icon={faRunning}
                    selected={planLength === '42k'}
                    gender="Maraton"
                    action={() => setPlanLength('42k')}
                />
            </div>
        </TrainingProfileLayout>
    )
}

export default TrainingProfilePlanLengthPageView
