import React from 'react'

import bannisterLogoWhite from '../assets/images/logo-white.png'

import styles from '../components/common.module.scss'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.wrapper}>
                <img src={bannisterLogoWhite} alt="logo" />
                {/* <div className={styles.info}> */}
                <p>© 2020 Bannister</p>
                <p>
                    <a href="https://bannister.coach/terms/" target="blank">
                        Terms
                    </a>{' '}
                    ·
                    <a href="https://bannister.coach/privacy-policy/" target="blank">
                        {' '}
                        Privacy
                    </a>{' '}
                    &
                    <a href=" https://bannister.coach/cookies/" target="blank">
                        {' '}
                        Cookies
                    </a>
                </p>
            </div>
        </footer>
    )
}

export default Footer
