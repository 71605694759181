import React from 'react'
import PropTypes from 'prop-types'

import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatStringWithLink } from '../../constants/utils'

import styles from './training-plan-page.module.scss'

const TrainingDetailsCard = ({ title, subtitle, time, zone, pace, zoneValue }) => {
    return (
        <div className={styles['training-card']}>
            <div className={styles.content}>
                {title && <p className={styles['card-title']}>{title}</p>}
                {(time || zone || zoneValue) && (
                    <div className={styles.details}>
                        {time && (
                            <div className={styles['time-wrapper']}>
                                <FontAwesomeIcon
                                    icon={faClock}
                                    color="#aaaaaa"
                                    style={{ marginRight: '5px' }}
                                />
                                <p className={styles.time}>{`${time} Min`}</p>
                            </div>
                        )}
                        {zone && (
                            <p className={styles.zone}>
                                {`${pace ? 'Pace' : 'Zone'} ${zone}`}
                            </p>
                        )}
                        {zoneValue && (
                            <p className={styles.time}>
                                {zoneValue[0]} - {zoneValue[1]} {pace ? '' : 'BPM'}
                            </p>
                        )}
                    </div>
                )}

                {subtitle && (
                    <div
                        className={styles.time}
                        style={{ marginTop: '5px' }}
                        dangerouslySetInnerHTML={{
                            __html: formatStringWithLink(subtitle)
                        }}
                    />
                )}
            </div>
        </div>
    )
}

TrainingDetailsCard.propTypes = {
    title: PropTypes.string,
    zone: PropTypes.number,
    time: PropTypes.number,
    pace: PropTypes.bool,
    zoneValue: PropTypes.arrayOf(PropTypes.number),
    subtitle: PropTypes.string
}

export default TrainingDetailsCard
