import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './generic-card.module.scss'

const GenericCard = ({ icon, selected, gender, action, disabled }) => {
    return (
        <Button
            onClick={action}
            onKeyPress={action}
            // eslint-disable-next-line
            className={selected ? `${styles['card-selected']} ${styles.card}` : styles.card}
            disabled={disabled}
        >
            <FontAwesomeIcon
                icon={icon}
                size="3x"
                color={selected ? '#f0f0f0' : '#aaaaaa'}
            />
            <p>{gender}</p>
        </Button>
    )
}

GenericCard.propTypes = {
    icon: PropTypes.objectOf(PropTypes.any),
    selected: PropTypes.bool,
    gender: PropTypes.string,
    action: PropTypes.func,
    disabled: PropTypes.bool
}

export default GenericCard
