import gql from 'graphql-tag'

export const GET_USER_DETAILS = gql`
    query GetUserDetails($userId: String!) {
        getUserDetails(userId: $userId) {
            email
            id
            firstName
            lastName
            step
            stravaIntegrated
        }
    }
`

export const GET_TRAINING_PLAN = gql`
    query GetTrainingPlan($uid: String!) {
        getTrainingProfile(uid: $uid) {
            hrRest
            hrrZones
            paceZones
            raceDate
            speedUnit
            trainingPlan
            workoutsMap
        }
    }
`

export const GET_USER_TRAINING_PROFILE = gql`
    query GetTrainingPlan($uid: String!) {
        getTrainingProfile(uid: $uid) {
            hrRest
            raceDate
            sex
            birthDay
            trainingPlanLength
            sixMinTimeTrailMinutes
            sixMinTimeTrailSeconds
            sixMinTimeTrails
        }
    }
`

export const GET_ALL_USERS = gql`
    query GetAllUsers {
        getAllUsers {
            email
            firstName
            id
            lastName
            lengthOfTraining
            raceDate
            accountCreatedAt
            trainingPlanStartDate
        }
    }
`
