import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { CREATE_USER_ACCOUNT } from '../../graphql/mutations'
import { authStore } from '../../store'

import CreateAccountPageView from './create-account-page-view'
import AccountLayout from '../../layout/account-layout'
import Loader from '../../components/loader'

export default function CreateAccountPageContainer() {
    const history = useHistory()

    const { dispatch } = useContext(authStore)
    const [error, setError] = useState()
    const [isSubmitting, setSubmitting] = useState(false)
    const [createUserAccountMutation] = useMutation(CREATE_USER_ACCOUNT, {
        errorPolicy: 'all'
    })

    function createAccount(values) {
        setSubmitting(true)
        // eslint-disable-next-line
        delete values.confirmPassword

        const toSaveUser = {
            ...values
        }

        createUserAccountMutation({
            variables: { userAccount: toSaveUser }
        }).then((res) => {
            if (res.errors) {
                if (res.errors[0].extensions.validationErrors) {
                    setError(res.errors[0].extensions.validationErrors[0])
                } else {
                    setError(res.errors[0].message)
                }
                setSubmitting(false)
            } else {
                setError()
                dispatch({ data: res.data.createUserAccount, type: 'LOGIN' })
                history.push('/create-profile')
            }
        })
    }

    if (isSubmitting) {
        return (
            <AccountLayout>
                <Loader />
            </AccountLayout>
        )
    }

    return (
        <CreateAccountPageView
            error={error}
            createAccount={createAccount}
            isSubmitting={isSubmitting}
        />
    )
}
