import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'

import Loader from '../../components/loader'

import TrainingPlanPageView from './training-plan-view'

import { GET_TRAINING_PLAN } from '../../graphql/queries'

import { authStore } from '../../store'

export default function TrainingPlanPageContainer() {
    const { state } = useContext(authStore)

    const { data: trainingPlanResponse, loading: fetchingUserTrainingPlan } = useQuery(
        GET_TRAINING_PLAN,
        {
            variables: { uid: state.user.id },
            fetchPolicy: 'network-only'
        }
    )

    if (fetchingUserTrainingPlan) {
        return <Loader />
    }

    return (
        <TrainingPlanPageView
            trainingPlanData={trainingPlanResponse.getTrainingProfile}
        />
    )
}
