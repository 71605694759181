import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

import styles from './modal.module.scss'

const CustomModal = ({ title, subtitle, children, open, onClose }) => {
    return (
        <Modal show={open} onHide={onClose} className={styles.modal}>
            <Modal.Header closeButton />
            <Modal.Body>
                <div className={styles.title}>{title}</div>
                <div
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{
                        __html: subtitle
                    }}
                />
                {children}
            </Modal.Body>
        </Modal>
    )
}

CustomModal.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    subtitle: PropTypes.string
}

export default CustomModal
