import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'

import {
    useTable,
    usePagination,
    useGlobalFilter,
    useFilters,
    useSortBy
} from 'react-table'

import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { parseDateWithoutTimezone } from '../../constants/utils'

function formatTimestamp(timestamp) {
    return format(parseDateWithoutTimezone(fromUnixTime(timestamp)), 'dd/MM/yyyy')
}

function convertMilisecondsToDate(timestamp) {
    const date = parseDateWithoutTimezone(parseInt(timestamp, 10))
    return [
        `0${date.getDate()}`.slice(-2),
        `0${date.getMonth() + 1}`.slice(-2),
        date.getFullYear()
    ].join('/')
}

function formatDate(date) {
    return format(parseDateWithoutTimezone(date), 'dd/MM/yyyy')
}

function renderIcon(isSortedDesc) {
    let sortIcon
    if (isSortedDesc) {
        sortIcon = (
            <FontAwesomeIcon
                icon={faLongArrowAltDown}
                size="lg"
                style={{ marginLeft: '5px' }}
            />
        )
    } else {
        sortIcon = (
            <FontAwesomeIcon
                icon={faLongArrowAltUp}
                size="lg"
                style={{ marginLeft: '5px' }}
            />
        )
    }

    return sortIcon
}

const columns = [
    {
        Header: 'Name',
        accessor: 'firstName',
        sortType: 'basic'
    },
    {
        Header: 'Surname',
        accessor: 'lastName',
        sortType: 'basic'
    },
    {
        Header: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Account creation date',
        accessor: 'accountCreatedAt',
        Cell: (props) => {
            return props.row.original.accountCreatedAt
                ? convertMilisecondsToDate(props.row.original.accountCreatedAt)
                : ''
        }
    },
    {
        Header: 'Length Of Training',
        accessor: 'lengthOfTraining',
        sortType: 'basic'
    },
    {
        Header: 'Training Plan Start Date',
        accessor: 'trainingPlanStartDate',
        sortType: 'basic',
        Cell: (props) =>
            props.row.original.trainingPlanStartDate
                ? formatDate(props.row.original.trainingPlanStartDate)
                : ''
    },
    {
        Header: 'Race Date',
        accessor: 'raceDate',
        sortType: 'basic',
        Cell: (props) =>
            props.row.original.raceDate ? formatDate(props.row.original.raceDate) : ''
    }
]

const AdministrationUsersTable = ({ users }) => {
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
        {
            columns,
            data: users
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    return (
        <table
            border="0"
            cellSpacing="0"
            cellPadding="0"
            className="table is-fullwidth"
            {...getTableProps()}
        >
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                        ? renderIcon(column.isSortedDesc)
                                        : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

AdministrationUsersTable.propTypes = {
    users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
}

export default AdministrationUsersTable
