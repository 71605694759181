import React from 'react'
import PropTypes from 'prop-types'

import MiddleContainer from './middle-container'

import styles from './common.module.scss'

const BackgroundImageContainer = ({
    imageSrc,
    children,
    logoSrc,
    margin,
    progress,
    center
}) => {
    return (
        <div
            style={{ background: `url(${imageSrc}) no-repeat center center fixed` }}
            className={`${styles['background-image']} ${
                margin === true && styles.height
            }`}
        >
            <MiddleContainer
                logoSrc={logoSrc}
                margin={margin}
                progress={progress}
                center={center}
            >
                {' '}
                {children}
            </MiddleContainer>
        </div>
    )
}

BackgroundImageContainer.propTypes = {
    imageSrc: PropTypes.string,
    logoSrc: PropTypes.string,
    children: PropTypes.node.isRequired,
    margin: PropTypes.bool,
    center: PropTypes.bool,
    progress: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

export default BackgroundImageContainer
