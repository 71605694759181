import React from 'react'
import { useQuery } from '@apollo/client'

import Loader from '../../components/loader'
import AdministrationPageView from './administration-page-view'

import { GET_ALL_USERS } from '../../graphql/queries'

export default function AdministrationPageContainer() {
    const { data: usersResponse, loading: fetchingUsersDetails } = useQuery(
        GET_ALL_USERS,
        {
            fetchPolicy: 'network-only'
        }
    )

    if (fetchingUsersDetails) {
        return <Loader />
    }

    return (
        <AdministrationPageView
            users={usersResponse.getAllUsers.filter((user) => user.id)}
        />
    )
}
