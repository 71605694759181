import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { authStore } from '../../../store'

import SectionTitle from '../section-title'
import ProfileRaceDate from './profile-race-date'
import ProfileRestingHr from './profile-hr'
import ProfileSixMinTime from './profile-6min'

import styles from './profile.module.scss'

const Profile = ({ userProfile }) => {
    const history = useHistory()
    const { dispatch } = useContext(authStore)

    function generateNewTrainingPlan() {
        dispatch({ data: { step: 'ACCOUNT_CREATED' }, type: 'UPDATE_STEP' })
        history.push('/create-profile')
    }
    return (
        <div>
            <SectionTitle title="Athlete Profile" />
            <ProfileRestingHr restingHr={userProfile.hrRest} />
            <ProfileSixMinTime sixMinTimeTrails={userProfile.sixMinTimeTrails} />
            <ProfileRaceDate raceDate={userProfile.raceDate} />
            <div className={styles.card}>
                <Button className="section-button" onClick={generateNewTrainingPlan}>
                    Create New Training Plan
                </Button>
            </div>
        </div>
    )
}

Profile.propTypes = {
    userProfile: PropTypes.objectOf(PropTypes.any)
}

export default Profile
