import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'

import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    ApolloLink,
    fromPromise,
    HttpLink
} from '@apollo/client'
import { getMainDefinition } from 'apollo-utilities'
import omitDeep from 'omit-deep-lodash'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'

import App from './App'

import { StateProvider } from './store'
import { REFRESH_ID_TOKEN } from './graphql/mutations'

let client

const cleanTypenameLink = new ApolloLink((operation, forward) => {
    const keysToOmit = ['__typename'] // more keys like timestamps could be included here

    const def = getMainDefinition(operation.query)
    if (def && def.operation === 'mutation') {
        operation.variables = omitDeep(operation.variables, keysToOmit)
    }
    return forward ? forward(operation) : null
})

const cache = new InMemoryCache()

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_HOST
})

const authLink = setContext((_, { headers }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
        return {
            headers: {
                ...headers,
                authorization: user ? `Bearer ${user.idToken}` : ''
            }
        }
    }
})

const getNewToken = () => {
    const user = JSON.parse(window.localStorage.getItem('user'))
    const refreshToken = user && user.refreshToken

    return client
        .mutate({
            mutation: REFRESH_ID_TOKEN,
            variables: {
                refreshIdToken: refreshToken
            }
        })
        .then((response) => {
            const newAccessToken = response.data.refreshIdToken.accessToken
            const newRefreshToken = response.data.refreshIdToken.refreshToken

            user.idToken = newAccessToken
            user.refreshToken = newRefreshToken

            window.localStorage.setItem('user', JSON.stringify(user))

            return newAccessToken
        })
        .catch(error => {
            window.confirm(error.message)
            window.localStorage.removeItem('user')
            window.location.pathname = '/'
        })
}

const link = ApolloLink.from([
    onError(({ networkError, operation, forward }) => {
        const user = JSON.parse(window.localStorage.getItem('user'))

        if (networkError && user) {
            if (networkError.statusCode === 400) {
                return fromPromise(
                    getNewToken().catch(() => {
                        window.localStorage.removeItem('user')
                    })
                )
                    .filter((value) => Boolean(value))
                    .flatMap((accessToken) => {
                        const oldHeaders = operation.getContext().headers

                        operation.setContext({
                            headers: {
                                ...oldHeaders,
                                authorization: `Bearer ${accessToken}`
                            }
                        })

                        return forward(operation)
                    })
            }
        }
    }),
    cleanTypenameLink.concat(httpLink)
])

client = new ApolloClient({
    cache,
    link: authLink.concat(link)
})

ReactDOM.render(
    <ApolloProvider client={client}>
        <StateProvider>
            <App />
        </StateProvider>
    </ApolloProvider>,
    document.getElementById('root')
)
