import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'

import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'

import AccountLayout from '../../layout/account-layout'

import Input from '../../components/input'

import styles from '../create-account-page/create-account-page.module.scss'

const LoginPageView = ({ error, login }) => {
    const initialValues = {
        email: '',
        password: ''
    }

    return (
        <AccountLayout title="Login" subtitle="Enter your information">
            <Formik
                validateOnBlur={false}
                initialValues={initialValues}
                validate={(values) => {
                    const errors = {}

                    if (!values.email) {
                        errors.email = 'Required'
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,18}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address'
                    }

                    if (!values.password) {
                        errors.password = 'Required'
                    } else if (values.password.length < 6) {
                        errors.password = 'Password must contain minimum 6 characters'
                    }

                    return errors
                }}
                onSubmit={(values) =>
                    // eslint-disable-next-line
                    login(values)}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    dirty,
                    isValid,
                    isSubmitting
                }) => (
                    <Form
                        className={styles['create-account-form']}
                        onSubmit={handleSubmit}
                    >
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Input
                                    icon={faEnvelope}
                                    placeholder="Email"
                                    name="email"
                                    type="text"
                                    value={values.email}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.email}
                                    error={errors.email}
                                />

                                <Input
                                    icon={faLock}
                                    placeholder="Password"
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.password}
                                    error={errors.password}
                                />
                            </Form.Group>
                        </Form.Row>
                        {error && <div className="server-error-message">{error}</div>}
                        <Button
                            variant="primary"
                            className="custom-button width100"
                            type="submit"
                            disabled={isSubmitting || !(dirty && isValid)}
                        >
                            Continue
                        </Button>
                    </Form>
                )}
            </Formik>
            <p className={styles['sign-in-link']}>
                You don&apos;t have an account?{' '}
                <Link to="/">Register here</Link>
            </p>
        </AccountLayout>
    )
}

LoginPageView.propTypes = {
    error: PropTypes.string,
    login: PropTypes.func
}

export default LoginPageView
