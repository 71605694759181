import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'

import AccountLayout from '../../layout/account-layout'
import PricingCard from './pricing-card'

import styles from './pricing-page.module.scss'

const PricingPage = () => {
    const [isSwitchOn, setIsSwitchOn] = useState(true)

    useEffect(() => {
        if (isSwitchOn === false) {
            localStorage.setItem('membership', 'MONTH')
        } else if (isSwitchOn === true) {
            localStorage.setItem('membership', 'YEAR')
        }
        // localStorage.setItem('step', 'create-account')
    }, [isSwitchOn])

    const onSwitchAction = () => {
        setIsSwitchOn(!isSwitchOn)
    }

    const isActiveLeft = isSwitchOn ? '' : 'is-active'
    const isActiveRight = isSwitchOn ? 'is-active' : ''
    return (
        <AccountLayout
            title="Choose the plan that works for you."
            subtitle=""
            backgroundImage=""
        >
            <Form>
                <Form.Label className={styles['switch-wrapper']}>
                    <span className={`${styles.label} ${styles[isActiveLeft]}`}>
                        Monthly
                    </span>
                    <Form.Check
                        type="switch"
                        onChange={onSwitchAction}
                        id="custom-switch"
                        label=""
                        checked={isSwitchOn}
                        className="custom-switch-md"
                    />
                    <span className={`${styles.label} ${styles[isActiveRight]}`}>
                        Annual
                    </span>
                </Form.Label>
            </Form>
            <div className={styles['switch-subtitle']}>
                {isSwitchOn ? (
                    <p>
                        Save <span className="pink">30%</span> with annual subscription
                    </p>
                ) : (
                    <p>What you will get</p>
                )}
            </div>
            <PricingCard type={isSwitchOn ? 'annual' : 'monthly'} />
            <p className={styles['sign-in']}>
                Already have an account? <Link to="/login">Sign In</Link>
            </p>
        </AccountLayout>
    )
}

export default PricingPage
