import React, { useContext } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import routes from './routes'
import PrivateRoute from './routes/private-route'
import RedirectIfAuthRoute from './routes/redirect-if-auth-route'
import { authStore } from './store'

import { stepToRouteMapper } from './constants/utils'

import './App.scss'

function App() {
    const { state } = useContext(authStore)

    function adminView(adminRoute) {
        if (state.isAuthenticated && state.user && state.user.role === 'admin') {
            return adminRoute
        }

        return <routes.LoginPageRoute />
    }

    return (
        <Router>
            <Switch>
                <Route path="/administration">
                    {adminView(<routes.AdministrationPageRoute />)}
                </Route>
                
                <PrivateRoute path="/profile" auth>
                    <routes.UserProfilePageRoute />
                </PrivateRoute>

                <PrivateRoute path="/training-plan">
                    <routes.TrainingPlanPageRoute />
                </PrivateRoute>

                <PrivateRoute path="/training-profile-speed-unit">
                    <routes.TrainingProfileSpeedUnitRoute />
                </PrivateRoute>

                <PrivateRoute path="/training-profile-program">
                    <routes.TrainingProfileProgramPageRoute />
                </PrivateRoute>

                <PrivateRoute path="/training-profile-race-date">
                    <routes.TrainingProfileRaceDatePageRoute />
                </PrivateRoute>

                <PrivateRoute path="/training-profile-plan-length">
                    <routes.TrainingProfilePlanLengthRoute />
                </PrivateRoute>

                <PrivateRoute path="/create-profile">
                    <routes.CreateProfilePage />
                </PrivateRoute>

                <RedirectIfAuthRoute path="/create-account" component={routes.CreateAccountPageRoute} />
                
                <RedirectIfAuthRoute path="/login" component={routes.LoginPageRoute} />

                {/* Handle not defined routes */}
                <Redirect
                    from="/*"
                    to={state.isAuthenticated ? `/${stepToRouteMapper[state.user.step]}` : '/create-account'}
                />
            </Switch>
        </Router>
    )
}

export default App
