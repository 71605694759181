import React from 'react'

import styles from '../components/common.module.scss'

const FooterLinks = () => {
    return (
        <div className={styles['footer-links']}>
            <p>© 2020 Bannister</p>
            <p>
                <a href="https://bannister.coach/terms/" target="blank">
                    Terms
                </a>{' '}
                ·
                <a href="https://bannister.coach/privacy-policy/" target="blank">
                    {' '}
                    Privacy
                </a>{' '}
                &
                <a href=" https://bannister.coach/cookies/" target="blank">
                    {' '}
                    Cookies
                </a>
            </p>
        </div>
    )
}

export default FooterLinks
