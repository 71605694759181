import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import PropTypes from 'prop-types'

import styles from './common.module.scss'

const Banner = ({ title, subtitle }) => {
    return (
        <Jumbotron className={styles.banner}>
            <div>
                <h1>{title}</h1>
                <p>{subtitle}</p>
            </div>
        </Jumbotron>
    )
}

Banner.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string
}

export default Banner
