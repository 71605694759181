import React from 'react'
import PropTypes from 'prop-types'

import TrainingProfileLayout from '../../layout/training-profile-layout'
import CustomOverlay from '../../components/tooltip'

import { weekDays, programLabels, mapOrder } from '../../constants/utils'

import styles from './training-profile-program-page.module.scss'

const TrainingProfileProgramView = ({
    generateTrainingPlan,
    addSelectedDay,
    isDaySelected,
    selectedDays,
    error,
    consent,
    setConsent,
    isSubmitting
}) => {
    const sortingArr = weekDays.map((weekDay) => weekDay.value)
    const orderedDays = mapOrder(selectedDays, sortingArr, 'value')
    const { trainingPlanLength } = JSON.parse(localStorage.getItem('training-profile'))
    const programLabel = (day) =>
        programLabels[trainingPlanLength][orderedDays.indexOf(day)]

    function handleChange() {
        setConsent(!consent)
    }

    return (
        <TrainingProfileLayout
            title="Select your Training Days"
            subtitle={`This is a 6 days per week ${trainingPlanLength} training program.
            <br>
            There are different types of training sessions.
            Select below to build your schedule.
            `}
            progress={100}
            action={generateTrainingPlan}
            disabled={isSubmitting || selectedDays.length <= 5 || consent === false}
            buttonText="Create Training Plan"
        >
            <div className={styles.cards}>
                {weekDays.map((day) => (
                    <CustomOverlay
                        key={day.value}
                        text="You can't select more than 6 days!"
                        display={selectedDays.length > 5}
                    >
                        <div className={styles.wrapper}>
                            <button
                                type="button"
                                className={`${
                                    isDaySelected(day).length
                                        ? `${styles['card-selected']} ${styles.card}`
                                        : styles.card
                                }`}
                                onClick={() => addSelectedDay(day)}
                            >
                                {day.label}
                            </button>
                            {isDaySelected(day).length ? (
                                <span className={styles.program}>
                                    {programLabel(day)}
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                    </CustomOverlay>
                ))}
            </div>
            {error && <div className="server-error-message">{error}</div>}
            <div className={styles['checkbox-wrapper']}>
                <label htmlFor="consent" onClick={handleChange}>
                    <input
                        name="consent"
                        type="checkbox"
                        style={{ marginRight: 5 }}
                        checked={consent}
                        onChange={handleChange}
                    />
                    By checking this box, I acknowledge that I have read, understood, and
                    agreed to the health disclaimer and waiver found{' '}
                    <a
                        target="blank"
                        href="https://bannister.coach/knowledge-base/bannister-medical-waiver-disclaimer/"
                    >
                        here
                    </a>
                    . I have also completed the PAR-Q+ questionnaire found{' '}
                    <a
                        target="blank"
                        href="https://bannister.coach/knowledge-base/the-physical-activity-readiness-questionnaire-for-everyone-par-q/"
                    >
                        here
                    </a>
                    .
                </label>
            </div>
        </TrainingProfileLayout>
    )
}

TrainingProfileProgramView.propTypes = {
    generateTrainingPlan: PropTypes.func,
    addSelectedDay: PropTypes.func,
    isDaySelected: PropTypes.func,
    selectedDays: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
    ),
    error: PropTypes.string,
    consent: PropTypes.bool,
    setConsent: PropTypes.func,
    isSubmitting: PropTypes.bool
}

export default TrainingProfileProgramView
