import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import differenceInDays from 'date-fns/differenceInDays'

import { parseDateWithoutTimezone } from '../../constants/utils'

import styles from './training-plan-page.module.scss'

const TrainingRaceDetails = ({ raceDate }) => {
    return (
        <div className={styles['race-details']}>
            <p className={styles.title}>
                <span className={styles.custom}> Race day </span>{' '}
                <span> {format(parseDateWithoutTimezone(raceDate), 'dd/MM/yyyy')} </span>
                <span className={styles.light}>
                    ( {differenceInDays(new Date(raceDate), new Date())} Day)
                </span>
            </p>
        </div>
    )
}

TrainingRaceDetails.propTypes = {
    raceDate: PropTypes.string
}

export default TrainingRaceDetails
